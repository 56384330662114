import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import 'dayjs/locale/en-gb';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import { useGetUsersQuery, useCreateVacationMutation, useUpdateVacationMutation } from '../../services/ardentApi';

function VacationForm({ selectedVacation }) {
    const [addVacation, { error: addError }] = useCreateVacationMutation();
    const [updateVacation, { error: updateError }] = useUpdateVacationMutation();
    const [formState, setFormState] = useState({ user: '', start_date: dayjs(), end_date: dayjs(), reason: '' });
    const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery();

    useEffect(() => {
        if (selectedVacation) {
            setFormState({
                user: selectedVacation.user,
                start_date: dayjs(selectedVacation.start_date),
                end_date: dayjs(selectedVacation.end_date),
                reason: selectedVacation.reason,
            });
        } else {
            setFormState({ user: '', start_date: dayjs(), end_date: dayjs(), reason: '' });
        }
    }, [selectedVacation]);

    const handleChange = (field) => (event) => {
        setFormState({ ...formState, [field]: event.target.value });
    };

    const handleDateChange = (field) => (newDate) => {
        setFormState({ ...formState, [field]: newDate });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { user, start_date, end_date, reason } = formState;

        try {
            const formattedStartDate = start_date.format('YYYY-MM-DD');
            const formattedEndDate = end_date.format('YYYY-MM-DD');

            if (selectedVacation) {
                await updateVacation({
                    id: selectedVacation.id,
                    user,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    reason,
                }).unwrap();
            } else {
                await addVacation({
                    user,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    reason,
                }).unwrap();
            }

            setFormState({ user: '', start_date: dayjs(), end_date: dayjs(), reason: '' });
        } catch (error) {
            console.error('Failed to save vacation:', error);
        }
    };

    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="h6">{selectedVacation ? 'Edit Vacation' : 'Add Vacation'}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            select
                            label="User"
                            value={formState.user}
                            onChange={handleChange('user')}
                            fullWidth
                            required
                            sx={{ flex: 1 }}
                        >
                            {isLoadingUsers ? (
                                <MenuItem disabled>Loading...</MenuItem>
                            ) : (
                                users && users.length > 0 ? (
                                    users.map((user) => (
                                        <MenuItem key={user.id} value={user.username}>
                                            {user.username}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>No users available</MenuItem>
                                )
                            )}

                        </TextField>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Start Date"
                                value={formState.start_date}
                                onChange={handleDateChange('start_date')}
                                TextField={(props) => <TextField {...props} fullWidth required sx={{ flex: 1 }} />}
                            />
                            <DatePicker
                                label="End Date"
                                value={formState.end_date}
                                onChange={handleDateChange('end_date')}
                                TextField={(props) => <TextField {...props} fullWidth required sx={{ flex: 1 }} />}
                                minDate={formState.start_date}
                            />
                        </LocalizationProvider>
                    </Box>
                    <TextField
                        label="Reason"
                        value={formState.reason}
                        onChange={handleChange('reason')}
                        fullWidth
                        required
                        margin="normal"
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2 }}>
                        <Button
                            type="reset"
                            variant="outlined"
                            onClick={() => setFormState({ user: '', start_date: dayjs(), end_date: dayjs(), reason: '' })}
                        >
                            Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            {selectedVacation ? 'Update' : 'Save'}
                        </Button>
                    </Box>
                </form>
            </AccordionDetails>
        </Accordion>
    );
}

export default VacationForm;
