import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { Container } from '@mui/system'
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import {
    GridRowModes,
    DataGridPremium,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid-premium';
import { useCreateFeedbackMutation, useGetFeedbackQuery, useUpdateFeedbackMutation, useDeleteFeedbackMutation } from '../services/ardentApi'
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../features/auth/authSlice';

export default function FeedbackAndFeatures() {
    const [feedback, setFeedback] = useState('');
    const [rows, setRows] = useState([])
    const [rowModesModel, setRowModesModel] = useState({});
    const { data, error, isLoading } = useGetFeedbackQuery();
    const selectedUser = useSelector(selectCurrentUser);
    const [createFeedback] = useCreateFeedbackMutation();
    const [updateFeedback] = useUpdateFeedbackMutation();
    const [deleteFeedback] = useDeleteFeedbackMutation();
    useEffect(() => {
        if (data) {
            setRows(data); // Populate rows state with fetched data
        }
    }, [data]);
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => async () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        // Find the index of the row with the matching id
        const rowIndex = rows.findIndex(row => row.id === id);
        console.log('rows',rows)
        console.log('rowIndex',rowIndex)
        // If the row is found, get its current feedback value
        if (rowIndex !== -1) {
          const currentFeedback = rows[rowIndex].feedback;
          // If the feedback has changed, update it on the server
          if (currentFeedback !== feedback) {
            try {
              // Update the feedback on the server
              const updatedFeedback = await updateFeedback({ id, data: feedback });
              // Update the row mode to view mode
              setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
            } catch (error) {
              console.error('Error editing feedback:', error);
            }
          } else {
            // If the feedback has not changed, just update the row mode to view mode
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
          }
        }
      };

    const handleDeleteClick = (id) => async () => {
        try {
            // Update the feedback on the server
            const deleteFeedbackMessage = await deleteFeedback({ id });
            // Update the row mode to view mode
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
          } catch (error) {
            console.error('Error editing feedback:', error);
          }
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        console.log('In process row update', updatedRow)
        // Try and send to db
        try {
            const updatedFeedback = await updateFeedback({ id: newRow.id, data: newRow.feedback });
            setRowModesModel({ ...rowModesModel, [newRow.id]: { mode: GridRowModes.View } });
          } catch (error) {
            console.error('Error editing feedback:', error);
          }
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleInputChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleSubmit = async () => {
        if (!feedback.trim()) {
            // If feedback is empty or contains only whitespace, do not submit
            return;
        }

        try {
            console.log(feedback)
            await createFeedback({ user: selectedUser, feedback });
            setFeedback(''); // Clear the input field after successful submission
        } catch (error) {
            console.error('Error creating feedback:', error);
        }
    };

    const columns = [
        { field: 'username', headerName: 'User', width: 180, editable: false },
        {
            field: 'feedback',
            headerName: 'Feedback',
            type: 'string',
            width: 650,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        {
            field: 'updated_at',
            headerName: 'Last Updated',
            type: 'datetime',
            width: 200,
            editable: false,
            renderCell: (params) => {
                const date = new Date(params.value);
                const options = {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                };
                return new Intl.DateTimeFormat('en-US', options).format(date);
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;

                if (selectedUser !== row.username) {
                    return []; // Return an empty array if the selectedUser is different from the row's username
                }

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={() => handleSaveClick(row.id)()} 
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(row.id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(row.id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(row.id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Container>
            <Box
                sx={{
                    flexGrow: 1,
                    marginTop: 1,
                    marginBottom: 2,

                }}
            >
                <Grid item xs={8} sm={8} md={8}>
                    <Typography>Enter Feedback Below</Typography>
                    <Input
                        fullWidth
                        label="Enter Feedback"
                        size='small'
                        onChange={handleInputChange}
                        sx={{
                            marginBottom: 1,
                        }}
                    />
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                    <Button type="submit" onClick={handleSubmit} variant="contained" color="primary" sx={{ marginRight: 1 }}>Submit</Button>
                </Grid>

            </Box>
            {error ? (
                <Alert severity="error">{error.error}</Alert>
            ) : (isLoading || !data )? (
                <CircularProgress />
            ) :
                <Box
                    sx={{
                        height: 500,
                    }}
                >
                    <DataGridPremium
                        rows={rows}
                        columns={columns}
                        editMode="row"
                        disableColumnMenu
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        rowHeight={35}
                        onEditCellChange={(params) => {
                            setRows(prevRows => {
                                const updatedRows = prevRows.map(row =>
                                    row.id === params.id ? { ...row, [params.field]: params.props.value } : row
                                );
                                return updatedRows;
                            });
                        }}
                    />
                </Box>
            }
        </Container>

    );
}