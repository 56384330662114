import React, { useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Typography, Box, Grid, Paper, Link } from '@mui/material';
import { useSubmitSECUrlMutation } from '../../services/ardentApi';


const FormComponent = () => {
  const [textValue, setTextValue] = useState('');
  const [selectValue, setSelectValue] = useState('');
  const [fetchedUrl, setFetchedUrl] = useState('');
  const [submitForm] = useSubmitSECUrlMutation();


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (textValue.length <= 4 && selectValue) {
      try {
        const result = await submitForm({ ticker: textValue, filingType: selectValue }).unwrap();
        console.log(result)
        setFetchedUrl(result.url);
      } catch (err) {
        console.error('Failed to submit the form: ', err);
      }
    } else {
      alert('Invalid input');
    }
  };

  return (
    <Paper style={{ padding: 12, border:  '1px solid #d3d3d3'  }}>
      <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
        <Typography variant='h6' gutterBottom>Fetch Filing URL</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
            size='small'
              label="Ticker"
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
              inputProps={{ maxLength: 4 }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="filing-type-label">Filing Type</InputLabel>
              <Select
              size='small'
                labelId="filing-type-label"
                value={selectValue}
                onChange={(e) => setSelectValue(e.target.value)}
                label="Filing Type"
              >
                <MenuItem value="10-K">10-K</MenuItem>
                <MenuItem value="10-Q">10-Q</MenuItem>
                <MenuItem value="8-K">8-K</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={2}>
        <Typography variant="body1">
          {fetchedUrl ? (
            <Link href={fetchedUrl} target="_blank" rel="noopener">
              {fetchedUrl}
            </Link>
          ) : 'No URL fetched yet.'}
        </Typography>
      </Box>
    </Paper>
  );
};

export default FormComponent;
