import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'auth',
    initialState: { user: null, token: null, roles: null},
    reducers: {
        setCredentials: (state, action) => {
            const { user, token, roles } = action.payload;
            state.user = user;
            state.token = token;
            state.roles = roles
            // Store user and token in local storage
            sessionStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('roles', JSON.stringify(roles));
        },
        resetCredentials: (state) => {
            state.user = null;
            state.token = null;
            state.roles = null;
            // Remove user and token from local storage
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('roles');
        },
    },
});

export const { setCredentials, resetCredentials } = slice.actions;

export default slice.reducer

export const selectCurrentUser = (state) => {
    const { user, token } = state.auth;
    return user && token ? user : null;
};

export const selectCurrentUserRoles = (state) => {
    const { roles } = state.auth;
    return  roles ? roles : null;
};