import React, { useState, useEffect } from 'react';
import { Box, FormControl, TextField, Typography, InputLabel, Select, MenuItem, OutlinedInput, Chip, Button } from '@mui/material';

const RVChartBondFilter = ({ bonds, onFilterChange, visiblePointsCount }) => {
    const [ccy, setCcy] = useState([]);
    const [selectedCcy, setSelectedCcy] = useState([]);
    const [bbCompositeRatings, setBbCompositeRatings] = useState([]);
    const [selectedRatings, setSelectedRatings] = useState([]);
    const [industryGroups, setIndustryGroups] = useState([]);
    const [selectedIndustryGroups, setSelectedIndustryGroups] = useState([]);
    const [industrySectors, setIndustrySectors] = useState([]);
    const [selectedIndustrySectors, setSelectedIndustrySectors] = useState([]);
    const [filteredBonds, setFilteredBonds] = useState([]);
    const [isZoomed, setIsZoomed] = useState(false); // State to track if zoom has been triggered
    const [minSpread, setMinSpread] = useState(''); // State for minimum YAS Bond Spread
    const [maxSpread, setMaxSpread] = useState(''); // State for maximum YAS Bond Spread

    // Initialize filter options from bond data
    useEffect(() => {
        const uniqueCcy = [...new Set(bonds.map((bond) => bond.ccy))].sort();
        setCcy(uniqueCcy);

        const uniqueRatings = [...new Set(bonds.map((bond) => bond.bb_composite_rating))]
            .sort((a, b) => getBbCompositeInteger(a) - getBbCompositeInteger(b));
        setBbCompositeRatings(uniqueRatings);

        const uniqueIndustryGroups = [...new Set(bonds.map((bond) => bond.industry_group))].sort();
        setIndustryGroups(uniqueIndustryGroups);

        const uniqueIndustrySectors = [...new Set(bonds.map((bond) => bond.industry_sector))].sort();
        setIndustrySectors(uniqueIndustrySectors);
    }, [bonds]);

    // Function to return bb_composite_integer for sorting
    const getBbCompositeInteger = (rating) => {
        const bbCompositeMapping = {
            'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'BBB+': 5, 'BBB': 6, 'BBB-': 7,
            'BB+': 8, 'BB': 9, 'BB-': 10, 'B+': 11, 'B': 12, 'B-': 13, 'CCC+': 14,
            'CCC': 15, 'CCC-': 16, 'CC+': 17, 'CC': 18, 'CC-': 19, 'DD+': 20, 'NR': 21
        };
        return bbCompositeMapping[rating] || 21; // Default to 21 if not found
    };

    // Filter bonds based on selected filters
    useEffect(() => {
        const filtered = bonds.filter((bond) => {
            const spread = bond.yas_bond_spread;

            // Apply numeric filter for YAS Bond Spread
            const spreadInRange =
                (minSpread === '' || spread >= parseFloat(minSpread)) &&
                (maxSpread === '' || spread <= parseFloat(maxSpread));

            return (
                spreadInRange &&
                (selectedCcy.length === 0 || selectedCcy.includes(bond.ccy)) &&
                (selectedRatings.length === 0 || selectedRatings.includes(bond.bb_composite_rating)) &&
                (selectedIndustryGroups.length === 0 || selectedIndustryGroups.includes(bond.industry_group)) &&
                (selectedIndustrySectors.length === 0 || selectedIndustrySectors.includes(bond.industry_sector))
            );
        });

        setFilteredBonds(filtered);
        onFilterChange({ filteredBonds: filtered }); // Pass filtered bonds to parent component
    }, [selectedCcy, selectedRatings, selectedIndustryGroups, selectedIndustrySectors, bonds, minSpread, maxSpread, onFilterChange]);

    // Handlers for filter changes
    const handleCcyChange = (event) => {
        setSelectedCcy(event.target.value);
    };

    const handleRatingChange = (event) => {
        setSelectedRatings(event.target.value);
    };

    const handleIndustryGroupChange = (event) => {
        setSelectedIndustryGroups(event.target.value);
        // Update sectors based on selected groups
        const relatedSectors = [...new Set(bonds.filter((bond) => event.target.value.includes(bond.industry_group))
            .map((bond) => bond.industry_sector))];
        setIndustrySectors(relatedSectors);
        setSelectedIndustrySectors([]);
    };

    const handleIndustrySectorChange = (event) => {
        setSelectedIndustrySectors(event.target.value);
    };

    // Handler to reset all filters
    const handleResetFilters = () => {
        setSelectedCcy([]);
        setSelectedRatings([]);
        setSelectedIndustryGroups([]);
        setSelectedIndustrySectors([]);
        setMinSpread('');
        setMaxSpread('');
        setIsZoomed(false);
    };

    // Function to update zoom state when the chart zooms
    useEffect(() => {
        if (visiblePointsCount !== null && visiblePointsCount !== undefined) {
            setIsZoomed(true); // Set zoom state if zoom has been triggered
        }
    }, [visiblePointsCount]);

    // Update the `isZoomed` state to false when minSpread or maxSpread changes
    useEffect(() => {
        if (minSpread !== '' || maxSpread !== '') {
            setIsZoomed(false);
        }
    }, [minSpread, maxSpread]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h6">RV Bond Filters</Typography>
            <Box sx={{ display: 'flex', gap: 2 }}> {/* Flexbox to arrange dropdowns in a row */}
                {/* Currency Filter */}
                <FormControl fullWidth>
                    <InputLabel>Currency</InputLabel>
                    <Select
                        multiple
                        value={selectedCcy}
                        onChange={handleCcyChange}
                        input={<OutlinedInput label="Currency" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                    >
                        {ccy.map((currency) => (
                            <MenuItem key={currency} value={currency}>
                                {currency}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* BB Composite Rating Filter */}
                <FormControl fullWidth>
                    <InputLabel>BB Composite Rating</InputLabel>
                    <Select
                        multiple
                        value={selectedRatings}
                        onChange={handleRatingChange}
                        input={<OutlinedInput label="BB Composite Rating" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                    >
                        {bbCompositeRatings.map((rating) => (
                            <MenuItem key={rating} value={rating}>
                                {rating}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Industry Group Filter */}
                <FormControl fullWidth>
                    <InputLabel>Industry Group</InputLabel>
                    <Select
                        multiple
                        value={selectedIndustryGroups}
                        onChange={handleIndustryGroupChange}
                        input={<OutlinedInput label="Industry Group" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                    >
                        {industryGroups.map((group) => (
                            <MenuItem key={group} value={group}>
                                {group}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Industry Sector Filter */}
                <FormControl fullWidth>
                    <InputLabel>Industry Sector</InputLabel>
                    <Select
                        multiple
                        value={selectedIndustrySectors}
                        onChange={handleIndustrySectorChange}
                        input={<OutlinedInput label="Industry Sector" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                    >
                        {industrySectors.map((sector) => (
                            <MenuItem key={sector} value={sector}>
                                {sector}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {/* YAS Bond Spread Numeric Filter */}
            <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
                <TextField
                    label="Min YAS Bond Spread"
                    value={minSpread}
                    onChange={(e) => setMinSpread(e.target.value)}
                    variant="outlined"
                    type="number"
                    sx={{ width: 150 }}
                />
                <TextField
                    label="Max YAS Bond Spread"
                    value={maxSpread}
                    onChange={(e) => setMaxSpread(e.target.value)}
                    variant="outlined"
                    type="number"
                    sx={{ width: 150 }}
                />
            </Box>

            {/* Reset Filters Button */}
            <Button
                variant="outlined"
                color="primary"
                onClick={handleResetFilters}
                sx={{ height: '56px', marginTop: 2 }} // Align with dropdown height
            >
                Reset Filters
            </Button>

            {/* Selected Bonds Count Display */}
            <TextField
                label="Selected Bonds Count"
                value={isZoomed ? visiblePointsCount : filteredBonds.length} // Display filteredBonds.length if isZoomed is reset due to changes in Y-axis filters
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
                sx={{ width: 200, marginTop: 2 }}
            />
        </Box>
    );
};

export default RVChartBondFilter;
