import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import TabPanel from './TabMenuBar'

const AddInterestMenu = () => {
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);


    return (
    
            <Accordion expanded={isAccordionExpanded} onChange={(event, isExpanded) => setIsAccordionExpanded(isExpanded)} sx={{ fontSize: '10px' }}>
                <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography variant="body1" fontWeight="bold"> Insert Record</Typography>
                </AccordionSummary>
               
        <TabPanel/>
            </Accordion>
       
    );
};

export default AddInterestMenu;
