import { Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'


export default function RiskScreen() {

    return (
            <Container>
                <Typography>
                This will be the Risk page
                </Typography>
                

            </Container>
    )
}