import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, Typography, CircularProgress } from '@mui/material';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import { useGetVacationsQuery } from '../../services/ardentApi';
import dayjs from 'dayjs';

export default function VacationCalendarGrid({ onSelect }) {
    const { data: vacations = [], isLoading } = useGetVacationsQuery();
    const [formattedVacations, setFormattedVacations] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
     
        if (vacations && vacations.length > 0) {
            setFormattedVacations(vacations.map(vacation => ({
                id: vacation.id,
                user: vacation.user,
                start_date: new Date(vacation.start_date), // Store as Date object
                end_date: new Date(vacation.end_date), // Store as Date object
                reason: vacation.reason,
            })));
        }
    }, [vacations]);

    const columns = [
      { field: 'id', headerName: 'ID', width: 150 },
        { field: 'user', headerName: 'User', width: 150 },
        {
            field: 'start_date',
            headerName: 'Start Date',
            width: 150,
            type: 'date',
            valueFormatter: ({ value }) => dayjs(value).format('MMM DD, YYYY'), // Format for display
        },
        {
            field: 'end_date',
            headerName: 'End Date',
            width: 150,
            type: 'date',
            valueFormatter: ({ value }) => dayjs(value).format('MMM DD, YYYY'), // Format for display
        },
        { field: 'reason', headerName: 'Reason', width: 300 },
    ];

    const handleRowSelection = (newSelection) => {
        if (newSelection.length > 0) {
            const selectedRowData = formattedVacations.find(row => row.id === newSelection[0]);
            setSelectedRow(selectedRowData);
            onSelect(selectedRowData); // Pass selected data to parent
        } else {
            setSelectedRow(null);
            onSelect(null);
        }
    };

    return (
        <Box sx={{ width: '100%', marginTop: '15px' }}>
            <CssBaseline>
                <Typography variant="h5" sx={{ paddingLeft: 1, marginBottom: 2 }}>
                    Vacation List
                </Typography>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGridPremium
                            rows={formattedVacations}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 20]}
                            disableSelectionOnClick
                            slots={{ toolbar: GridToolbar }}
                            onSelectionModelChange={handleRowSelection} // Handle selection
                            selectionModel={selectedRow ? [selectedRow.id] : []} // Set selected row
                        />
                    </Box>
                )}
            </CssBaseline>
        </Box>
    );
}
