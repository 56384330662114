import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Input,
  InputAdornment,
  IconButton,
  Box,
  Button,
  Divider,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../services/ardentApi';
import logo from '../static/images/ardFC.png'; // replace with the actual path to your logo image

function PasswordInput({ name, onChange }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Input
      type={showPassword ? 'text' : 'password'}
      placeholder="Enter password"
      name={name}
      onChange={onChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={handleTogglePasswordVisibility} edge="end">
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
}

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({ username: '', password: '' });
  const [login, { error: loginError, isLoading }] = useLoginMutation();
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevFormState) => ({ ...prevFormState, [name]: value }));
  };

  const handleLogin = async () => {
    try {
      const user = await login(formState).unwrap();
      dispatch(setCredentials({ user: user.username, token: user.access, roles: user.employee }));
      navigate('/interests');
    } catch (error) {
      console.log(error)
      if (error['status'] === 'FETCH_ERROR') {
        setError('Unable to connect to database.')
      } else {
        setError(error['data']['detail']); // Set error message
      }
      
    }
  };

  return (
    <AppBar position="static"  style={{  backgroundColor: '#f0f0f0' }}>
      <Toolbar>
        <img src={logo} alt="Ardent Financial" style={{ height: 50, marginRight: 'auto' }} />
        <Box component="form" sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          <Input
            onChange={handleChange}
            name="username"
            type="text"
            placeholder="Enter username"
            sx={{ marginRight: 2 }}
          />
          <PasswordInput onChange={handleChange} name="password" />
          <Button
            variant="contained"
            onClick={handleLogin}
            sx={{ marginLeft: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </Box>
      </Toolbar>
      {error && <Alert severity="error">{error}</Alert>} {/* Display error message */}
    </AppBar>
  );
};

export default Login;
