import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useGetOrderConstantsQuery, useGetSalesCounterpartiesQuery, useGetSecuritiesQuery, useAddInterestMutation } from '../../services/ardentApi';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/auth/authSlice';
import dayjs from 'dayjs'

function ensureKeyExists(obj, key, defaultValue) {
    if (!(key in obj)) {
        obj[key] = defaultValue;  // Set default value if key does not exist
    }
    return obj;
}


const AddSingleInterest = () => {
    const selectedUser = useSelector(selectCurrentUser);
    const [counterparties, setCounterparties] = useState([]);
    const [selectedIssuer, setSelectedIssuer] = useState('');
    // Using a query hook automatically fetches data and returns query values
    const { data: counterpartyData } = useGetSalesCounterpartiesQuery();
    const { data: securityData } = useGetSecuritiesQuery();
    const { data: orderConstantsData } = useGetOrderConstantsQuery();
    const [addInterest, { error }] = useAddInterestMutation(); // Call the useAddPostMutation hook
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false)
    const [clearFields, setClearFields] = useState(true)
    const [showSuccess, setShowSuccess] = useState(false)
    let uniqueIssuersList = []
      // Initialize the date state to null
  const [selectedDate, setSelectedDate] = useState(null);

  // Function to handle date changes
  const handleDateChange = (date, setFieldValue) => {
    setFieldValue('good_until', date ? dayjs(date).toISOString() : '');
};

    const initialFormData = {
        counterparty: '',
        good_until: '',
        orderType: '',
        verb: '',
        geography: '',
        size: 0,
        price: 0,
        issuer: '',
        security: '',
        notes: ''
    };
    if (securityData && securityData.length > 0) {
        const uniqueIssuers = new Set(securityData.map(security => security.issuer));
        uniqueIssuersList = [...uniqueIssuers]
    }
    const handleIssuerChange = (event, formikProps) => {
        setSelectedIssuer(event.target.value);
        // Update formikProps with selected issuer
        formikProps.setFieldValue('issuer', event.target.value);
        if (formikProps.values.security.length > 0) {
            // Filter the security list 
            formikProps.setFieldValue('security', '')
        }
    };

    const handleIssueChange = (event, formikProps) => {
        const issuerObj = securityData.find(security => security.figi_ticker === event.target.value);
        setSelectedIssuer(issuerObj.issuer);
        formikProps.setFieldValue('issuer', issuerObj.issuer)
        // Update formikProps with selected issuer
        formikProps.setFieldValue('security', event.target.value);
    };

    const handleSubmit = async (values, { resetForm }) => {
        const counterpartyValue = JSON.parse(values.counterparty);
        try {
            // Lookup issuer if there is a security in the record
            let figi = ''
            const selectedSecurity = securityData.find(security => security.figi_ticker === values.security);
            if (selectedSecurity) {
                figi = selectedSecurity.figi
            }
            // Check if the Order Type is an empty string, if yes then set to Info
            if (!values.orderType) {
                values.orderType = 'Info';
            }
            const dataPayload = {
                ...values,
                id: counterpartyValue.id,
                notes: values.notes.trim(),
                user: selectedUser ? selectedUser : "",
                figi: figi
            };
            console.log('dataPayload', dataPayload)
            // Check if a good_until date is present if a hard order
            if (dataPayload && dataPayload.orderType === 'Firm') {
                if (!dataPayload.good_until || dataPayload.good_until.trim() === '') {
                    setErrorMessage('Firm orders must have a good until date!')
                    setShowError(true);
                    setShowSuccess(false)
                    // Hide the alert after 3 seconds
                    setTimeout(() => {
                        setShowError(false);
                    }, 10000); // 3000 milliseconds = 3 seconds
                    return
                }
            }
            
            
            try {
                ensureKeyExists(dataPayload,'good_until', '')
                const result = await addInterest(dataPayload)

                if (result.error) {
                    setErrorMessage(result.error.data.detail);
                    console.log("addInterest result", result.error.data.detail)
                    setShowError(true);
                    setShowSuccess(false)
                    // Hide the alert after 3 seconds
                    setTimeout(() => {
                        setShowError(false);
                    }, 10000); // 3000 milliseconds = 3 seconds
                } else {
                    setShowError(false);
                    setShowSuccess(true)
                    // Hide the alert after 3 seconds
                    setTimeout(() => {
                        setShowSuccess(false);

                    }, 3000); // 3000 milliseconds = 3 seconds
                    clearFields && resetForm()
                    setSelectedIssuer('')
                }

            } catch {
                console.error('Failed to send interest to db: ', error)
            }
            console.log('Form values:', values);

            // Reset the form after successful submission
            clearFields && resetForm()
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    useEffect(() => {
        if (counterpartyData && selectedUser) {
            const filteredData = counterpartyData.filter(item => item.username === selectedUser);
            const names = filteredData.map(item => ({ 'id': item.id, 'name': item.name + '-' + item.region }))
            setCounterparties(names);
        } else {
            // If either counterpartyData or selectedUser is empty, set counterparties to an empty array
            setCounterparties([]);
        }
    }, [counterpartyData, selectedUser]);

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const filteredData = counterpartyData.filter(item => item.username === selectedUser);
            const names = filteredData.map(item => ({ 'id': item.id, 'name': item.name + '-' + item.region }))
            setCounterparties(names);
        } else {
            const names = counterpartyData.map(item => ({ 'id': item.id, 'name': item.name + '-' + item.region }))
            setCounterparties(names);
        }
    };

    const handleSaveFieldsCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setClearFields(false)
        } else {
            setClearFields(true)
        }
    };

    return (
        <Box sx={{
            flexGrow: 1,
            marginTop: 2,
            marginBottom: 1,
        }}>
            <Formik
                initialValues={initialFormData}
                // validationSchema={validationSchema} // NOT USING YUP VALIDATION
                onSubmit={handleSubmit}
            >
                {(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <Grid container spacing={2} sx={{ padding: 1 }}>
                            <Grid item xs={4} sm={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="counterparty">Counterparty</InputLabel>
                                    <Select
                                        required
                                        id="counterparty"
                                        label='Counterparty'
                                        name='counterparty'
                                        value={formikProps.values.counterparty}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        error={formikProps.touched.counterparty && Boolean(formikProps.errors.counterparty)}
                                    >
                                        {counterparties.map((counterparty) => (
                                            <MenuItem key={counterparty.id} value={JSON.stringify({ id: counterparty.id, name: counterparty.name })}>
                                                {counterparty.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked onChange={handleCheckboxChange} />}
                                        label="My Clients"
                                        sx={{ fontSize: '8px' }}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="orderType">Record Type</InputLabel>
                                    <Select
                                        required
                                        id="orderType"
                                        label='Order type'
                                        name='orderType'
                                        value={formikProps.values.orderType}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        error={formikProps.touched.orderType && Boolean(formikProps.errors.orderType)}
                                    >
                                        {orderConstantsData && orderConstantsData.order_types.map((type) => (
                                            <MenuItem key={type} value={type}>{type}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={2} container spacing={1} sx={{ padding: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Field name="good_until">
                                        {({ form, field }) => (
                                            <DateTimePicker
                                                label="Good Until"
                                                value={field.value ? dayjs(field.value) : null}
                                                onChange={(newValue) => {
                                                    handleDateChange(newValue, form.setFieldValue);
                                                }}
                                                minDate={dayjs()} 
                                                slotProps={{ textField: { variant: 'outlined' } }}
                                            />
                                        )}
                                    </Field>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="verb">Verb</InputLabel>
                                    <Select
                                        required
                                        id="verb"
                                        label='verb'
                                        name='verb'
                                        value={formikProps.values.verb}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        error={formikProps.touched.verb && Boolean(formikProps.errors.verb)}
                                    >
                                        {orderConstantsData && orderConstantsData.order_verbs
                                            // Conditional filtering based on orderType value
                                            .filter((verb) =>
                                                formikProps.values.orderType !== 'Firm' || verb === 'Buyer' || verb === 'Seller'
                                            )
                                            .map((verb) => (
                                                <MenuItem key={verb} value={verb}>{verb}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={2} xs={4} sm={2}>
                                <TextField
                                    label="Size (mm)"
                                    name="size"
                                    type="number"
                                    size="small"
                                    inputProps={{ step: 'any', min: 0 }} // Allows decimal numbers
                                    value={formikProps.values.size}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    error={formikProps.touched.size && Boolean(formikProps.errors.size)}
                                />
                            </Grid>
                            <Grid item md={2} xs={4} sm={2}>
                                <TextField
                                    label="Price"
                                    name="price"
                                    type="number"
                                    size="small"
                                    inputProps={{ step: 'any', min: 0 }} // Allows decimal numbers
                                    value={formikProps.values.price}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    error={formikProps.touched.size && Boolean(formikProps.errors.size)}
                                />
                            </Grid>
                            <Grid item md={2} xs={2} sm={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="issuer">Issuer</InputLabel>
                                    <Select
                                        id="issuer"
                                        label='Issuer'
                                        name='issuer'
                                        value={formikProps.values.issuer}
                                        onChange={(event) => handleIssuerChange(event, formikProps)} // Pass formikProps to handleIssuerChange

                                    >
                                        {uniqueIssuersList && uniqueIssuersList.map((issuer) => (
                                            <MenuItem key={issuer} value={issuer}>{issuer}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={4} sm={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="security">Security</InputLabel>
                                    <Select
                                        id="security"
                                        label='Security'
                                        name='security'
                                        value={formikProps.values.security}
                                        onChange={(event) => handleIssueChange(event, formikProps)} // Pass formikProps to handleIssuerChange
                                        onBlur={formikProps.handleBlur}
                                        error={formikProps.touched.security && Boolean(formikProps.errors.security)}
                                    >
                                        {securityData && securityData
                                            .filter(security => !selectedIssuer || security.issuer === selectedIssuer) // Conditionally filter securities based on selected issuer
                                            .map(security => (
                                                <MenuItem key={security.figi_ticker} value={security.figi_ticker}>{security.figi_ticker}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={6} sm={8}>
                                <TextField
                                    fullWidth
                                    label="Notes"
                                    name="notes"
                                    type="text"
                                    size="small"
                                    value={formikProps.values.notes}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    error={formikProps.touched.notes && Boolean(formikProps.errors.notes)}
                                />
                            </Grid>

                            <Grid item xs={6} sm={6} container justifyContent="flex-end">
                                <Grid item xs={4} sm={4}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Submit
                                    </Button>

                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => {
                                            // Reset each form input's value to empty string or zero
                                            formikProps.setValues(initialFormData)
                                            setSelectedIssuer('')
                                        }}
                                    >
                                        Clear
                                    </Button>

                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleSaveFieldsCheckboxChange} />}
                                            label="Save Fields"
                                            sx={{ fontSize: '8px' }}
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>

            {showError && <Alert severity="error">{errorMessage}</Alert>}
            {showSuccess && (
                <Alert severity="success" style={{ marginTop: '20px' }}>
                    Record added successfully.
                </Alert>
            )}
        </Box>
    );
};

export default AddSingleInterest;
