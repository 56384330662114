import { blue, green, lightBlue, orange, pink, purple, red } from '@mui/material/colors';

export const COLORS = [
  pink[500],
  blue[500],
  orange[500],
  red[500],
  green[500],
  lightBlue[500],
  purple[500],
];
export const PAGES = ['Interests', 'CRM', 'Research',  'Trading', 'Blotter', 'Risk',  'Feedback', 'Admin']




