import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const isProduction = process.env.NODE_ENV === 'production';

const baseUrl = isProduction
  ? process.env.REACT_APP_PROD_API_URL
  : process.env.REACT_APP_DEV_API_URL;

console.log("REACT_APP_DEV_API_URL: ", baseUrl)

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ username, password }) => ({
        url: '/api/users/login',
        method: 'POST',
        body: { username, password, },
        headers: {
          'Content-Type': 'application/json'
        },
      }),
    }),
    getAllInterests: builder.query({
      query: () => '/interests',
      providesTags: ['Interests'], // Tag data with 'Interests'
    }),
    getSalesCounterparties: builder.query({
      query: () => "/crm/form-counterparties",
    }),
    getSecurities: builder.query({
      query: () => "/securities",
      providesTags: ['Securities'], 
    }),
    addSecurity: builder.mutation({
      query: (body) => ({
        url: '/add-security/',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      invalidatesTags: ['Interests', 'OrderConstants', 'Securities' ], // Invalidate cache associated with 'Interests' tag
    }),
    getOrderConstants: builder.query({
      query: () => "/order-constants",
      providesTags: ['OrderConstants'], 
    }),
    addInterest: builder.mutation({
      query: (body) => ({
        url: '/add-interest/',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      invalidatesTags: ['Interests'], // Invalidate cache associated with 'Interests' tag
      onError: (error) => {
        console.error('addInterest mutation error:', error)
      }
    }),
    updateInterest: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-interest/${id}/`,
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      invalidatesTags: ['Interests'], // Invalidate cache associated with 'Interests' tag
      onError: (error) => {
        console.error('updateInterest mutation error:', error)
      }
    }),
    getAllCounterparties: builder.query({
      query: () => 'crm/counterparties/',
      providesTags: ['Counterparties'], // Tag data with 'Counterparties'
    }),
    addCounterparty: builder.mutation({
      query: (body) => ({
        url: '/crm/add-counterparty/',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      invalidatesTags: ['Counterparties'], // Invalidate cache associated with 'Interests' tag
      onError: (error) => {
        console.error('addCounterparty mutation error:', error)
      }
    }),
    getCounterpartyConstants: builder.query({
      query: () => "crm/counterparty-constants",
    }),
    updateCounterparty: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-counterparty/${id}/`,
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      invalidatesTags: ['Counterparties'], // Invalidate cache associated with 'Interests' tag
      onError: (error) => {
        console.error('updateCounterparty mutation error:', error)
      }
    }),
    getInterestConstants: builder.query({
      query: () => "interest-grid-constants/",
    }),
    getFeedback: builder.query({
      query: () => "feedback/",
      providesTags: ['Feedback'], // Tag data with 'Feedback'
    }),
    createFeedback: builder.mutation({
      query: (body) => ({
        url: '/create-feedback/',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      invalidatesTags: ['Feedback'], // Invalidate cache associated with 'Interests' tag
      onError: (error) => {
        console.error('createFeedback mutation error:', error)
      }
    }),
    updateFeedback: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-feedback/${id}/`,
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      invalidatesTags: ['Feedback'], 
      onError: (error) => {
        console.error('updateFeedback mutation error:', error)
      }
    }),
    deleteFeedback: builder.mutation({
      query: ({ id, data }) => ({
        url: `/delete-feedback/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      invalidatesTags: ['Feedback'], 
      onError: (error) => {
        console.error('deleteFeedback mutation error:', error)
      }
    }),
    createTrade: builder.mutation({
      query: (body) => ({
        url: '/create-trade/',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      invalidatesTags: ['Blotter', 'Positions'], 
      onError: (error) => {
        console.error('createTrade mutation error:', error)
      }
    }),
    getTrades: builder.query({
      query: () => "get-trades/",
      providesTags: ['Blotter'],
    }),
    deleteTrade: builder.mutation({
      query: ({ id, data }) => ({
        url: `/delete-trade/${id}/`,
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      invalidatesTags: ['Blotter'], 
      onError: (error) => {
        console.error('deleteTrade mutation error:', error)
      }
    }),
    getPositons: builder.query({
      query: () => "get-positions/",
      providesTags: ['Positions'],
    }),
    getBlotterMO: builder.query({
      query: () => "get-blotter-mo/",
      providesTags: ['BlotterMO'],
    }),
    getBondMonitor: builder.query({
      query: () => "get-bond-monitor/",
      providesTags: ['BondMonitor'],
    }),
    getPnL: builder.query({
      query: () => "get-pnl/",
      providesTags: ['PnL'],
    }),
    getTemporaryCredentials: builder.query({
      query: () => "get-temporary-credentials/",
    }),
    getSignedUrl: builder.query({
      query: (fileName) => `get-signed-url/?filename=${fileName}`,
    }),
    getPresignedUrl: builder.query({
      query: ({ filename, filetype }) => ({
        url: 'get-presigned-url/',
        method: 'GET',
        params: { filename, filetype },
      }),
    }),
    getFiling: builder.query({
      query: ({ ticker, filing_typ }) => ({
        url:  'get-sec-filing/',
        method: 'GET',
        params: {ticker, filing_typ}
      })
    }),
    submitSECUrl: builder.mutation({
      query: (data) => ({
        url: '/get-sec-url/',
        method: 'POST',
        body: data,
      }),
    }),
    getVacations: builder.query({
      query: () => ({
        url:  'get-vacations/',
        method: 'GET',
      }),
      providesTags: ['Vacations'],
    }),
    getUsers: builder.query({
      query: () => ({
        url:  'get-users/',
        method: 'GET',
      })
    }),
    createVacation: builder.mutation({
      query: (body) => ({
        url: '/vacation-create/',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      invalidatesTags: ['Vacations'], 
      onError: (error) => {
        console.error('createTrade mutation error:', error)
      }
    }),
    updateVacation: builder.mutation({
      query: (body) => ({
        url: `/vacation-update/${body.id}/`, // Assuming the API endpoint requires the ID
        method: 'PUT', // HTTP PUT method is typically used for updates
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      invalidatesTags: ['Vacations'], // Invalidate cache to refetch updated list
      onError: (error) => {
        console.error('updateVacation mutation error:', error);
      }
    }),
    getRVBonds: builder.query({
      query: () => "rvchartbonds/",
      providesTags: ['RVChart'],
    }),
  }),
});

export const { useLoginMutation, useGetAllInterestsQuery, useGetSalesCounterpartiesQuery, 
  useGetSecuritiesQuery, useGetOrderConstantsQuery, useAddInterestMutation, useUpdateInterestMutation,
   useGetAllCounterpartiesQuery, useAddCounterpartyMutation, useUpdateCounterpartyMutation ,useGetCounterpartyConstantsQuery,useGetInterestConstantsQuery,
  useAddSecurityMutation, useGetFeedbackQuery, useCreateFeedbackMutation, useUpdateFeedbackMutation,
useDeleteFeedbackMutation, useCreateTradeMutation, useGetTradesQuery, useDeleteTradeMutation, useGetPositonsQuery,
useGetBlotterMOQuery, useGetBondMonitorQuery, useGetPnLQuery, useLazyGetPresignedUrlQuery,
useGetTemporaryCredentialsQuery, useLazyGetSignedUrlQuery, useGetFilingQuery, useSubmitSECUrlMutation, useGetVacationsQuery,
useGetUsersQuery, useCreateVacationMutation, useUpdateVacationMutation, useGetRVBondsQuery,
  } = api;