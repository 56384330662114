import React, { useState, Fragment } from 'react';
import { Alert, Box, Divider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import 'dayjs/locale/en-gb';
import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../features/auth/authSlice';
import VacationCalendarGrid from '../components/admin/VacationCalendarGrid';
import VacationForm from '../components/admin/VacationForm';

export default function AdminScreen() {
    const selectedUserRoles = useSelector(selectCurrentUserRoles);
    const [selectedVacation, setSelectedVacation] = useState(null);

    const handleSelect = (vacation) => {
        setSelectedVacation(vacation);
        console.log(vacation)
    };
    console.log('hello')

    return (
        <Fragment>
            <CssBaseline>
                <Box sx={{ height: '90vh', width: '80%', padding: 2 }}>
                    {selectedUserRoles && selectedUserRoles.is_management ? (
                        <Box>
                            <VacationForm selectedVacation={selectedVacation} />
                            <Divider />
                            <VacationCalendarGrid onSelect={handleSelect} />
                        </Box>
                    ) : (
                        <Alert severity="error">Not Authorized</Alert>
                    )}
                </Box>
            </CssBaseline>
        </Fragment>
    )
}
