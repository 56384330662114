import React, { useState, useEffect } from 'react';
import * as Yup from 'yup'
import { Formik, Form } from 'formik';
import Accordion from '@mui/material/Accordion';
import Alert from '@mui/material/Alert';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography'; import { Box, TextField, Button, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useAddCounterpartyMutation, useGetCounterpartyConstantsQuery } from '../../services/ardentApi';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/auth/authSlice';

const initialFormData = {
  name: '',
  secondary_sales: '',
  region: '',
  type: '',
  coverage_status: '',
  tier: '',
  aum: 0,
  sales_tier: '',
  line_status: '',
  on_runs: false,
  have_chat: false,
  have_email: false,
  city: '',
  notes_type: '',
  contacts: '',
  notes: '',
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Name is required'),
});

const AddCounterpartyForm = () => {
  const [addCounterparty, { error }] = useAddCounterpartyMutation(); 
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const selectedUser = useSelector(selectCurrentUser);
  const { data: counterpartyConstantData } = useGetCounterpartyConstantsQuery();
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [secondarySales, setSecondarySales] = useState('')

  useEffect(() => {
    const salesUsernames = counterpartyConstantData.users
      .filter(user =>user.employee &&  user.employee.is_sales && user.username !== selectedUser) // Filter based on your conditions
      .map(user => user.username); // Extract the username
    if (counterpartyConstantData && selectedUser) {
      setSecondarySales(salesUsernames);
    }
  }, [counterpartyConstantData, selectedUser]);

  const handleSubmit = async (values, { resetForm }) => {

    try {
      const dataPayload = {
        ...values,
        name: values.name.trim(),
        contacts: values.contacts.trim(),
        user: selectedUser ? selectedUser : ""
      };
      try {
        console.log('dataPayload: ', dataPayload)
        const result = await addCounterparty(dataPayload)
        console.log("addCounterparty result", result)
        if (result.error) {
          setErrorMessage(result.error.data.detail);
          console.log("addInterest result", result.error.data.detail)
          setShowError(true);
          setShowSuccess(false)
          // Hide the alert after 3 seconds
          setTimeout(() => {
            setShowError(false);
          }, 10000); // 3000 milliseconds = 3 seconds
        } else {
          setShowError(false);
          setShowSuccess(true)
          // Hide the alert after 3 seconds
          setTimeout(() => {
            setShowSuccess(false);
          }, 3000); // 3000 milliseconds = 3 seconds
        }
      } catch {
        console.error('Failed to send counterparty to db: ', error)
      }
      // Reset the form after successful submission
      resetForm();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
 
      <Accordion expanded={isAccordionExpanded} onChange={(event, isExpanded) => setIsAccordionExpanded(isExpanded)} sx={{ fontSize: '10px' }}>
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body1" fontWeight="bold"> Add Counterparty</Typography>
        </AccordionSummary>
        <Formik
          initialValues={initialFormData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit}>
              <Grid container spacing={1} sx={{ padding: 1 }}>
                <Grid item md={3} xs={6} sm={4}>
                  <TextField
                    required
                    size='small'
                    id="name"
                    name="name"
                    label="Name"
                    value={formikProps.values.name}
                    onChange={formikProps.handleChange}
                    error={formikProps.touched.name && Boolean(formikProps.errors.name)}
                    helperText={formikProps.touched.name && formikProps.errors.name}
                  />
                </Grid>
                <Grid item md={2} xs={6} sm={4}>
                  <FormControl fullWidth >
                    <InputLabel id="second-sales-label">Secondary Sales</InputLabel>
                    <Select
                      size="small"
                      label='Secondary Sales'
                      name='secondary_sales'
                      value={formikProps.values.secondary_sales}
                      onChange={formikProps.handleChange}
                    >
                      {secondarySales && secondarySales.map((secondary_sales, index) => (
                        <MenuItem key={index} value={secondary_sales}>{secondary_sales}</MenuItem>
                      ))}
                    </Select >
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={6} sm={4}>
                  <FormControl fullWidth >
                    <InputLabel id="region-label">Region</InputLabel>
                    <Select
                      required
                      size="small"
                      label='Region'
                      name='region'
                      value={formikProps.values.region}
                      onChange={formikProps.handleChange}
                    >
                      {counterpartyConstantData && counterpartyConstantData.regions.map((region, index) => (
                        <MenuItem key={index} value={region}>{region}</MenuItem>
                      ))}
                    </Select >
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={6} sm={4}>
                  <FormControl fullWidth >
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                      required
                      size="small"
                      label='Type'
                      name='type'
                      value={formikProps.values.type}
                      onChange={formikProps.handleChange}
                    >
                      {counterpartyConstantData && counterpartyConstantData.type.map((type, index) => (
                        <MenuItem key={index} value={type}>{type}</MenuItem>
                      ))}
                    </Select >
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={6} sm={4}>
                  <FormControl fullWidth >
                    <InputLabel id="type-label">Status</InputLabel>
                    <Select
                      required
                      size="small"
                      label='Status'
                      name='coverage_status'
                      value={formikProps.values.coverage_status}
                      onChange={formikProps.handleChange}
                    >
                      {counterpartyConstantData && counterpartyConstantData.coverage_status.map((coverage_status, index) => (
                        <MenuItem key={index} value={coverage_status}>{coverage_status}</MenuItem>
                      ))}
                    </Select >
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={1} sx={{ padding: 1 }}>
                <Grid item md={2} xs={2} sm={2}>
                  <FormControl fullWidth >
                    <InputLabel id="type-label">Tier</InputLabel>
                    <Select
                      size="small"
                      label='Tier'
                      name='tier'
                      value={formikProps.values.tier}
                      onChange={formikProps.handleChange}
                    >
                      {counterpartyConstantData && counterpartyConstantData.tier.map((tier, index) => (
                        <MenuItem key={index} value={tier}>{tier}</MenuItem>
                      ))}
                    </Select >
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={2} sm={2}>
                  <TextField
                    size='small'
                    id="aum"
                    name="aum"
                    label="AUM (bn)"
                    type='number'
                    inputProps={{ step: 'any', min: 0 }} // Allows decimal numbers
                    value={formikProps.values.aum}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
                <Grid item md={2} xs={2} sm={2}>
                  <FormControl fullWidth >
                    <InputLabel id="type-label">Sales Tier</InputLabel>
                    <Select
                      size="small"
                      label='Sales Tier'
                      name='sales_tier'
                      value={formikProps.values.sales_tier}
                      onChange={formikProps.handleChange}
                    >
                      {counterpartyConstantData && counterpartyConstantData.tier.map((tier, index) => (
                        <MenuItem key={index} value={tier}>{tier}</MenuItem>
                      ))}
                    </Select >
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={2} sm={2}>
                  <FormControl fullWidth >
                    <InputLabel id="type-label">Line Status</InputLabel>
                    <Select
                      required
                      size="small"
                      label='Line Status'
                      name='line_status'
                      value={formikProps.values.line_status}
                      onChange={formikProps.handleChange}
                    >
                      {counterpartyConstantData && counterpartyConstantData.line_status.map((line_status, index) => (
                        <MenuItem key={index} value={line_status}>{line_status}</MenuItem>
                      ))}
                    </Select >
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={2} sm={2}>
                  <TextField
                    size='small'
                    id="city"
                    name="city"
                    label="City"
                    value={formikProps.values.city}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
                <Grid item md={2} xs={2} sm={2}>
                  <TextField
                    size='small'
                    id="notesType"
                    name="notes_type"
                    label="Notes Type"
                    value={formikProps.values.notes_type}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ padding: 1 }}>
                <Grid item xs={6} sm={4} md={6} >
                  <TextField
                    fullWidth
                    size='small'
                    id="contacts"
                    name="contacts"
                    label="Contacts"
                    value={formikProps.values.contacts}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={6}  >
                  <TextField
                    fullWidth
                    size='small'
                    id="notes"
                    name="notes"
                    label="Notes"
                    value={formikProps.values.notes}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ padding: 1 }}>
                <Grid item xs={4} sm={4} md={4} >
                  <FormControlLabel
                    control={<Checkbox checked={formikProps.values.on_runs} onChange={formikProps.handleChange} name="on_runs" />}
                    label="On Runs"
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} >
                  <FormControlLabel
                    control={<Checkbox checked={formikProps.values.have_chat} onChange={formikProps.handleChange} name="have_chat" />}
                    label="Have Chat"
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} >
                  <FormControlLabel
                    control={<Checkbox checked={formikProps.values.have_email} onChange={formikProps.handleChange} name="have_email" />}
                    label="Have Email"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ padding: 1 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ marginRight: 1 }}
                >Submit</Button>
                <Button color="error" variant="contained"
                  onClick={() => {
                    // Reset each form input's value to empty string or zero
                    formikProps.setValues(initialFormData);
                  }}
                  sx={{ marginLeft: 10 }}>
                  Clear
                </Button>
                {showError && <Alert sx={{ marginLeft: 10 }} severity="error">{errorMessage}</Alert>}
                {showSuccess && (
                  <Alert sx={{ marginLeft: 10 }} severity="success" style={{ marginTop: '20px' }}>
                    Record added successfully.
                  </Alert>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Accordion>
  );
}

export default AddCounterpartyForm