import React, { useState, useEffect, useCallback, Fragment, useLayoutEffect } from 'react'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import {
    DataGridPremium,
    GridToolbar,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import AddCounterpartyForm from '../components/counterparties/AddCounterpartyForm';
import { useGetAllCounterpartiesQuery, useGetCounterpartyConstantsQuery, useUpdateCounterpartyMutation } from '../services/ardentApi'
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../features/auth/authSlice';
import CircularProgress from '@mui/material/CircularProgress';

export default function CRMScreen() {
    const { data: serverData, error: getCounterpartyError, isLoading: counterpartyLoading } = useGetAllCounterpartiesQuery()
    const { data: gridData, error: getConstantsError, isLoading: constantsLoading } = useGetCounterpartyConstantsQuery()
    const selectedUser = useSelector(selectCurrentUser);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(null)
    const [updateCounterpartyMutation] = useUpdateCounterpartyMutation();
    const [initialState, setInitialState] = useState();
    const apiRef = useGridApiRef();
    const [secondarySales, setSecondarySales] = useState([])
    const [allSecondarySales, setAllSecondarySales] = useState([])
    
    useEffect(() => {
        if (gridData && selectedUser) {
            const salesUsernames = gridData.users
                .filter(user => user.employee && user.employee.is_sales && user.username !== selectedUser) // Filter based on your conditions
                .map(user => user.username); 
    
            const allSalesUsernames = gridData.users.map(user => user.username);
    
            setSecondarySales(salesUsernames);
            setAllSecondarySales(allSalesUsernames);
        }
    }, [gridData, selectedUser]);
    const columns = [
        {
            field: 'name',
            headerName: 'Counterparty',
            type: 'string',
            width: 150,
            editable: true,
        },
        {
            field: 'username',
            headerName: 'Sales',
            type: 'singleSelect',
            width: 100,
            editable: true,
            valueOptions: allSecondarySales && allSecondarySales.map(item => ({ label: item, value: item })),
            valueParser: (newValue) => allSecondarySales && allSecondarySales.includes(newValue) ? newValue : '', // Parse the entered value
            valueGetter: (params) => allSecondarySales && allSecondarySales.includes(params.value) ? params.value : '', // Display the parsed value
        },
        {
            field: 'second_sales',
            headerName: 'No 2 Sales',
            type: 'singleSelect',
            width: 100,
            editable: true,
            valueOptions: secondarySales && secondarySales.map(item => ({ label: item, value: item })),
            valueParser: (newValue) => secondarySales && secondarySales.includes(newValue) ? newValue : '', // Parse the entered value
            valueGetter: (params) => secondarySales && secondarySales.includes(params.value) ? params.value : '', // Display the parsed value
        },
        {
            field: 'region',
            headerName: 'Region',
            type: 'singleSelect',
            width: 100,
            editable: true,
            valueOptions: gridData && gridData.regions.map(item => ({ label: item, value: item })),
            valueParser: (newValue) => gridData && gridData.regions.includes(newValue) ? newValue : '', // Parse the entered value
            valueGetter: (params) => gridData && gridData.regions.includes(params.value) ? params.value : '', // Display the parsed value
        },
        {
            field: 'coverage_status',
            headerName: 'Status',
            type: 'singleSelect',
            width: 120,
            editable: true,
            valueOptions: gridData && gridData.coverage_status.map(item => ({ label: item, value: item })),
            valueParser: (newValue) => gridData && gridData.coverage_status.includes(newValue) ? newValue : '', // Parse the entered value
            valueGetter: (params) => gridData && gridData.coverage_status.includes(params.value) ? params.value : '', // Display the parsed value
        },
        {
            field: 'type',
            headerName: 'Type',
            type: 'singleSelect',
            width: 80,
            editable: true,
            valueOptions: gridData && gridData.type.map(item => ({ label: item, value: item })),
            valueParser: (newValue) => gridData && gridData.type.includes(newValue) ? newValue : '', // Parse the entered value
            valueGetter: (params) => gridData && gridData.type.includes(params.value) ? params.value : '', // Display the parsed value
        },
        {
            field: 'tier',
            headerName: 'Tier',
            type: 'number',
            width: 80,
            editable: true,
            renderCell: (params) => {
                const selectedValue = params.row.tier;
                return isNaN(selectedValue) ? '' : selectedValue; // Display the selected value in the cell
            },
            valueParser: (newValue) => {
                console.log("newValue:", newValue)
                const parsedValue = parseInt(newValue, 10);
                if (isNaN(parsedValue)) {
                    return 1; // Default to 1 if parsing fails
                }
                return Math.min(Math.max(parsedValue, 1), 5); // Clamp the value between 1 and 5
            },
            valueGetter: (params) => {
                const value = parseInt(params.value, 10);
                return isNaN(value) ? '' : value; // Return empty string if value is not a number
            }
        },
        {
            field: 'aum',
            headerName: 'AUM (bn)',
            type: 'number',
            editable: true,
            width: 90,
        },
        {
            field: 'sales_tier',
            headerName: 'Sales Tier',
            type: 'number',
            width: 120,
            editable: true,
        },
        {
            field: 'line_status',
            headerName: 'Line Status',
            type: 'singleSelect',
            width: 100,
            editable: true,
            valueOptions: gridData && gridData.line_status.map(item => ({ label: item, value: item })),
            valueParser: (newValue) => gridData && gridData.line_status.includes(newValue) ? newValue : '', // Parse the entered value
            valueGetter: (params) => gridData && gridData.line_status.includes(params.value) ? params.value : '', // Display the parsed value
        },
        {
            field: 'on_runs',
            headerName: 'On Runs',
            type: 'boolean',
            width: 100,
            editable: true,
        },
        {
            field: 'have_chat',
            headerName: 'Have Chat',
            type: 'boolean',
            width: 100,
            editable: true,

        },
        {
            field: 'have_email',
            headerName: 'Have Email',
            type: 'boolean',
            width: 100,
            editable: true,

        },
        {
            field: 'city',
            headerName: 'City',
            type: 'string',
            width: 100,
            editable: true,
        },
        {
            field: 'notes_type',
            headerName: 'Notes/Type',
            type: 'string',
            width: 100,
            editable: true,
        },
        {
            field: 'contacts',
            headerName: 'Contacts',
            type: 'string',
            sortable: false,
            width: 100,
            editable: true,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            type: 'string',
            sortable: false,
            width: 250,
            editable: true,
        },
    ]

    const processRowUpdate = useCallback(
        async (updatedRow) => {
            const id = updatedRow.id;
            try {
                console.log('updatedRow: ',updatedRow)
                const updateResponse = await updateCounterpartyMutation({ id, data: updatedRow }).unwrap();
                
                // Update local state with the edited row data
                setRows((prevRows) =>
                    prevRows.map((row) => (row.id === updatedRow.id ? { ...row, ...updateResponse.data } : row))
                );
                return { ...updatedRow, ...updateResponse.data }; // Return the updated row data
            } catch (error) {
                console.error('Error updating counterparty:', error);
                throw error; // Rethrow the error to let the data grid know the update failed
            }
        },
        [updateCounterpartyMutation], // Dependencies
    );

    // Update local rows state whenever serverData changes
    useEffect(() => {
        if (serverData) {
            setRows(serverData);
        }
    }, [serverData]);

    const handleProcessRowUpdateError = (error) => {
        setError(error)
    }

    useEffect(() => {
        if (getCounterpartyError || getConstantsError) {
            setError(getCounterpartyError || getConstantsError);
        } else {
            setError(null);
        }
    }, [getCounterpartyError, getConstantsError]);

    const saveSnapshot = useCallback(() => {
        if (apiRef?.current?.exportState && localStorage) {
            const currentState = apiRef.current.exportState();
            localStorage.setItem('blotterDataGridState', JSON.stringify(currentState));
        }
    }, [apiRef]);

    useLayoutEffect(() => {
        const stateFromLocalStorage = localStorage?.getItem('crmDataGridState');
        setInitialState(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {});

        // handle refresh and navigating away/refreshing
        window.addEventListener('beforeunload', saveSnapshot);

        return () => {
            // in case of an SPA remove the event-listener
            window.removeEventListener('beforeunload', saveSnapshot);
            saveSnapshot();
        };
    }, [saveSnapshot]);

    if ( counterpartyLoading || constantsLoading || !serverData || !gridData) {
        return <CircularProgress />;
    }

    console.log("Initial state: ", initialState)
    return (
        <Fragment >
            <CssBaseline>
                {error ? (
                    <Alert severity="error">{error.error}</Alert>
                ) :
                <Box sx={{ height: '90vh', width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <AddCounterpartyForm />
                        <Box sx={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto', paddingTop: 2 }}>
                        <DataGridPremium
                            columns={columns}
                            rows={rows}
                            editMode="row"
                            pagination
                            processRowUpdate={processRowUpdate}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                            isCellEditable={(params) => params.row.username === selectedUser}
                            rowHeight={38}
                            apiRef={apiRef}
                            initialState={{
                                pagination: {
                                    pageSize: 50, // Ensure initialState includes the pageSize
                                },
                                ...initialState,
                            }}
                            disableColumnReorder // TEMPORARY Figure out render sequence
                            slots={{ toolbar: GridToolbar }}
                            sx={{
                                flexGrow: 1,
                                '& .MuiDataGrid-footerContainer': {
                                    position: 'sticky',
                                    bottom: 0,
                                    zIndex: 1,
                                    backgroundColor: '#fff', // Ensure footer stays visible on scroll
                                },
                            }}
                        />
                        </Box>
                    </Box>
                }
            </CssBaseline>
        </Fragment>

    )
}