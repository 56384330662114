import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import {
    DataGridPremium,
    GridActionsCellItem,
} from '@mui/x-data-grid-premium';
import { useDeleteTradeMutation, useGetTradesQuery, useGetPositonsQuery } from '../services/ardentApi'
import AddTrade from '../components/trading/AddTrade';
import AddSecurity from '../components/add-security/AddSecurity';
import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../features/auth/authSlice';

export default function TradingScreen() {
    const selectedUserRoles = useSelector(selectCurrentUserRoles);
    const [rows, setRows] = useState([])
    const [posRows, setPosRows] = useState([])
    const [deleteTrade] = useDeleteTradeMutation();
    const { data: positionData, isLoading: isLoadingPositions } = useGetPositonsQuery();
    const { data: blotterData, isLoading: isLoadingBlotter } = useGetTradesQuery();
    const [isSecAccordionExpanded, setIsSecAccordionExpanded] = useState(false);
    const [isTradeAccordionExpanded, setIsTradeAccordionExpanded] = useState(false);
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [error, setError] = useState({})

    const BorderedTypography = styled(Typography)({
        border: '1px solid #D3D3D3',
        padding: '8px',
      });

    useEffect(() => {
        if (blotterData) {
            setRows(blotterData); // Populate rows state with fetched data
        }
    }, [blotterData]);

    useEffect(() => {
        if (positionData) {
            setPosRows(positionData); // Populate rows state with fetched data
            console.log(positionData)
        }
    }, [positionData]);


    const handleDeleteClick = useCallback((id) => async () => {
        try {
            // Update the feedback on the server
            const response = await deleteTrade({ id });

            console.log(response)
            // Check if the response contains an 'error' key
            if (response.error) {
                setError(response.error);
                setShowError(true);
                setShowSuccess(false)
                // Hide the alert after 3 seconds
                setTimeout(() => {
                    setShowError(false);
                }, 3000); // 3000 milliseconds = 3 seconds
            } else {
                setShowError(false);
                setShowSuccess(true)
                // Hide the alert after 3 seconds
                setTimeout(() => {
                    setShowSuccess(false);
                }, 3000); // 3000 milliseconds = 3 seconds
            }
        } catch (error) {
            setShowError(true)
            setError(error)
            console.error('Error deleting trade:', error);
        }
    }, [deleteTrade]);

    const posColumns = [
        {
            field: 'isin',
            headerName: 'ISIN',
            type: 'text',
            width: 150,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'figi_ticker',
            headerName: 'Security',
            type: 'string',
            width: 250,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'ccy',
            headerName: 'Ccy',
            type: 'string',
            width: 100,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'position',
            headerName: 'Net Position',
            type: 'number',
            width: 100,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'residual_px',
            headerName: 'Avg Price',
            type: 'number',
            width: 100,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'mtm_px',
            headerName: 'MTM Price',
            type: 'number',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'calculated',
            headerName: 'MTM P&L',
            width: 160,
            valueGetter: (params) =>
              params.row.position > 0
                ? params.row.mtm_px - params.row.residual_px
                : params.row.residual_px - params.row.mtm_px,
          },
    ]

    const columns = useMemo(() => {
        const dynamicColumns = [
        {
            field: 'created_at',
            headerName: 'Timestamp',
            type: 'datetime',
            width: 200,
            editable: false,
            renderCell: (params) => {
                if (!params.value || isNaN(new Date(params.value).getTime())) {
                    return ''; // Return a placeholder or an indication of missing data
                }
                const date = new Date(params.value);
                const options = {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                };
                return new Intl.DateTimeFormat('en-US', options).format(date);
            },
        },
        {
            field: 'verb',
            headerName: 'Verb',
            type: 'singleSelect',
            width: 100,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            valueOptions: ['Buy', 'Sell'],
        },
        {
            field: 'notional',
            headerName: 'Size',
            type: 'number',
            sortable: false,
            editable: true,
            align: 'left',
            headerAlign: 'left',
            width: 70,
        },
        {
            field: 'figi_ticker',
            headerName: 'Security',
            type: 'string',
            width: 200,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        {
            field: 'ccy',
            headerName: 'Ccy',
            type: 'string',
            width: 100,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'price',
            headerName: 'Price',
            type: 'number',
            sortable: false,
            editable: true,
            width: 70,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            type: 'string',
            sortable: false,
            editable: true,
            width: 250,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: selectedUserRoles && selectedUserRoles.is_trading ? 'Delete' : '',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                if (selectedUserRoles && selectedUserRoles.is_trading) {
                    return [
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={handleDeleteClick(row.id)}
                            color="inherit"
                        />,
                    ];
                }
                return []
            },
        },
    ];
    if(selectedUserRoles && !selectedUserRoles.mask_counterparty) {
        dynamicColumns.splice(0,0, {
             field: 'counterparty_name',
             headerName: 'Counterparty',
             type: 'string',
             sortable: false,
             width: 150,
         })
     }
     return dynamicColumns;
 }, [selectedUserRoles, handleDeleteClick, ])

    return (
        <Box sx={{ width: '90%', padding: 1 }}>

            <Box
                sx={{
                    flexGrow: 1,
                    marginTop: 1,
                    marginBottom: 2,
                }}
            >
                <Accordion expanded={isSecAccordionExpanded} onChange={(event, isExpanded) => setIsSecAccordionExpanded(isExpanded)} sx={{ fontSize: '10px' }}>
                    <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography variant="body2" fontWeight="bold"> Add Security</Typography>
                    </AccordionSummary>

                    <AddSecurity />

                </Accordion>
            </Box>

            <Box
                sx={{
                    flexGrow: 1,
                    marginTop: 1,
                    marginBottom: 2,

                }}
            >
                <Accordion expanded={isTradeAccordionExpanded} onChange={(event, isExpanded) => setIsTradeAccordionExpanded(isExpanded)} sx={{ fontSize: '10px' }}>
                    <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography variant="body2" fontWeight="bold"> Insert Trade</Typography>
                    </AccordionSummary>
                    {selectedUserRoles && selectedUserRoles.is_trading ?  <AddTrade />
                    :   <Alert severity="error">Not Authorised</Alert>}
                   
                </Accordion>
            </Box>

            {showError ? (
                <Alert severity="error">{error.status}</Alert>
            ) : (isLoadingBlotter || !blotterData) ? (
                <CircularProgress />
            ) :

                <Box
                    sx={{
                        height: 500,
                    }}
                >
                    {showSuccess && (
                        <Alert severity="success" style={{ marginTop: '20px' }}>
                            Trade deleted successfully.
                        </Alert>
                    )}
                    <BorderedTypography variant="h6" gutterBottom style={{ paddingLeft: '5px' }} >
                        Blotter
                    </BorderedTypography>
                    <DataGridPremium
                        rows={rows}
                        columns={columns}
                        rowHeight={35}
                        disableColumnMenu
                    />
                </Box>
            }

            {selectedUserRoles && !isLoadingPositions && (selectedUserRoles.is_trading || selectedUserRoles.is_management || selectedUserRoles.is_risk)
            ?
            <Box sx={{ marginTop: 10, width: '100%'}}>
                <Grid container spacing={2} sx={{ padding: 0 }}>
                
                            <Grid item md={12} xs={12} sm={12}>
            <BorderedTypography variant="h6" gutterBottom style={{ paddingLeft: '5px' }} >
                        Positions
                    </BorderedTypography>
                    </Grid>
                   
                    </Grid>
                <DataGridPremium
                    rows={posRows}
                    columns={posColumns}
                    rowHeight={35}
                    disableColumnMenu
                    getRowId={(row) => row.isin}
                />
            </Box>
            :
            <Box></Box>
            }
           
        </Box>


    );
}