import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { selectCurrentUser } from '../features/auth/authSlice';
import Login from './LoginScreen';
import './HomeScreen.css';
import CityImg from '../static/images/city.png'; // Import the PNG file

const HomeScreen = () => {
  const selectedUser = useSelector(selectCurrentUser);
 
  return (
    <>

    <Login />
    <div>
<img src={CityImg} alt="City of London" className="fullscreen-img" />
    </div>
    </>
    
  );
};

export default HomeScreen;