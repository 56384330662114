import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useGetAllCounterpartiesQuery, useGetSecuritiesQuery, useCreateTradeMutation } from '../../services/ardentApi';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/auth/authSlice';
import dayjs from 'dayjs'


// Define initial form values
const initialValues = {
    counterparty: '',
    verb: '',
    size: '',
    security: '',
    price: '',
    notes: '',
};

export default function AddTrade() {
    const selectedUser = useSelector(selectCurrentUser);
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [counterparties, setCounterparties] = useState([]);
    const [securities, setSecurities] = useState([]);
    const { data: counterpartyData } = useGetAllCounterpartiesQuery();
    const { data: securityData } = useGetSecuritiesQuery();
    const [addTrade, { error: tradeError }] = useCreateTradeMutation();

  // Function to handle date changes
  const handleDateChange = (date, setFieldValue) => {
    setFieldValue('trade_date', date ? dayjs(date).toISOString() : '');
};

    const handleSubmit = async (values, { resetForm }) => {
        const counterpartyValue = JSON.parse(values.counterparty);
        console.log("Selected counterparty ID:", counterpartyValue.id);
        let figi = ''
        const selectedSecurity = securityData.find(security => security.figi_ticker === values.security);
        if (selectedSecurity) {
            figi = selectedSecurity.figi
        }
        const dataPayload = {
            ...values,
            id:  counterpartyValue.id,
            notes: values.notes.trim(),
            user: selectedUser ? selectedUser : "",
            figi: figi,
        };

        console.log('Form values:', dataPayload);

        try {
            const result = await addTrade(dataPayload)
            console.log("addInterest result", result)
            resetForm()
            if (result.error) {
                setErrorMessage(result.error.error);
                setShowError(true);
                setShowSuccess(false)
                // Hide the alert after 3 seconds
                setTimeout(() => {
                    setShowError(false);
                    setErrorMessage(''); // Optionally clear the message
                }, 3000); // 3000 milliseconds = 3 seconds
            } else {
                // Trade has been 
                setShowError(false);
                setShowSuccess(true)
                // Hide the alert after 3 seconds
                setTimeout(() => {
                    setShowSuccess(false);
                    setErrorMessage(''); // Optionally clear the message
                }, 3000); // 3000 milliseconds = 3 seconds
            }

        } catch {
            setShowSuccess(false)
            setErrorMessage(`Failed to send trade to db: ${tradeError}`);
            setShowError(true)
            console.error('Failed to send trade to db: ', tradeError)
        }
    };

    useEffect(() => {
        console.log(counterpartyData)
        if (counterpartyData) {
            const names = counterpartyData.map(item => ({ 'id': item.id, 'name': item.name + '-' + item.region }))
            setCounterparties(names);
        } else {
            // If either counterpartyData or selectedUser is empty, set counterparties to an empty array
            setCounterparties([]);
        }
    }, [counterpartyData]);

    useEffect(() => {
        if (securityData) {
            const securities = securityData.map(item => item.figi_ticker)
            setSecurities(securities);
        } else {
            // If either counterpartyData or selectedUser is empty, set counterparties to an empty array
            setSecurities([]);
        }
    }, [securityData]);

    return (
        <Box>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {formik => (
                    <Form>
                        <Grid container spacing={2} sx={{ padding: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Field name="trade_date">
                                        {({ form, field }) => (
                                            <DateTimePicker
                                                label="Trade Date"
                                                value={field.value ? dayjs(field.value) : dayjs()}
                                                onChange={(newValue) => {
                                                    handleDateChange(newValue, form.setFieldValue);
                                                }}
                                                minDate={dayjs()} 
                                                slotProps={{ textField: { variant: 'outlined' } }}
                                            />
                                        )}
                                    </Field>
                                </LocalizationProvider>
                            <Grid item md={2} xs={2} sm={2} >
                                <FormControl fullWidth size="small">
                                    <InputLabel id="counterparty">Counterparty</InputLabel>
                                    <Select
                                        required
                                        id="counterparty"
                                        name='counterparty'
                                        value={formik.values.counterparty}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.counterparty && Boolean(formik.errors.counterparty)}

                                    >
                                        {counterparties.map((counterparty) => (
                                            <MenuItem key={counterparty.id} value={JSON.stringify({ id: counterparty.id, name: counterparty.name })}>
                                                {counterparty.name}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={2} xs={4} sm={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="verb">Verb</InputLabel>
                                    <Select
                                        required
                                        id="verb"
                                        name='verb'
                                        value={formik.values.verb}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.verb && Boolean(formik.errors.verb)}

                                    >
                                        <MenuItem key='buy' value={"Buy"}>Buy</MenuItem>
                                        <MenuItem ke='sell' value={"Sell"}>Sell</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={2} xs={4} sm={2}>
                                <TextField
                                    label="Size (mm)"
                                    name="size"
                                    type="number"
                                    size="small"
                                    inputProps={{ step: 'any', min: 0 }} // Allows decimal numbers
                                    value={formik.values.size}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.size && Boolean(formik.errors.size)}

                                />
                            </Grid>
                            <Grid item md={4} xs={4} sm={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="security">Security</InputLabel>
                                    <Select
                                        id="security"
                                        name='security'
                                        value={formik.values.security}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.security && Boolean(formik.errors.security)}

                                    >
                                        {securities && securities.map((security) => (
                                            <MenuItem key={security} value={security}>{security}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={2} xs={4} sm={2}>
                                <TextField
                                    label="Price"
                                    name="price"
                                    type="number"
                                    size="small"
                                    inputProps={{ step: 'any', min: 0 }} // Allows decimal numbers
                                    value={formik.values.price}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.size && Boolean(formik.errors.size)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ padding: 1 }}>
                            <Grid item md={6} xs={6} sm={8}>

                                <TextField
                                    fullWidth
                                    label="Notes"
                                    name="notes"
                                    type="text"
                                    size="small"
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.notes && Boolean(formik.errors.notes)}
                                />

                            </Grid>
                            <Grid item md={6} xs={6} sm={8}>
                                <Button color="primary" variant="contained" type="submit">
                                    Submit
                                </Button>
                            </Grid>
                            {showError && (
                                <Alert severity="error" style={{ marginTop: '20px' }}>
                                    {errorMessage}
                                </Alert>
                            )}
                            {showSuccess && (
                                <Alert severity="success" style={{ marginTop: '20px' }}>
                                    Trade added successfully.
                                </Alert>
                            )}
                        </Grid>
                    </Form>

                )}
            </Formik>
        </Box>
    );
};

