import React, { useState, useEffect, useCallback, useMemo, useLayoutEffect, Fragment } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import {
    DataGridPremium,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarColumnsButton,
    useGridApiRef,
} from '@mui/x-data-grid-premium';

import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { renderStatus } from '../services/renderStatus'
import { useGetAllInterestsQuery, useUpdateInterestMutation, useGetInterestConstantsQuery } from '../services/ardentApi'
import CircularProgress from '@mui/material/CircularProgress';
import AddInterestMenu from '../components/interests/AddInterestMenu';
import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../features/auth/authSlice';
import { selectCurrentUser } from '../features/auth/authSlice';


function NoExportToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}



export default function OrderGrid() {
    // Using a query hook automatically fetches data and returns query values
    const { data, error: interestFetchError, isLoading } = useGetAllInterestsQuery()
    const { data: gridConstantsData, error: constantError, isLoading: constantsLoading } = useGetInterestConstantsQuery();
    const [updateInterestMutation] = useUpdateInterestMutation();
    const [editRowsModel, setEditRowsModel] = useState({});
    const selectedUser = useSelector(selectCurrentUser);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(null)
    const selectedUserRoles = useSelector(selectCurrentUserRoles);
    const [isManagement, setIsManagement] = useState(false)
    const [isRisk, setIsRisk] = useState(false)
    const [initialState, setInitialState] = useState();
    const apiRef = useGridApiRef();
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)


    function prependToColumns(dynamicColumns, newItem) {
        dynamicColumns.splice(0, 0, newItem);  // This modifies dynamicColumns directly
        return dynamicColumns;  // Now it includes the new item at the start
    }

    const columns = useMemo(() => {
        const dynamicColumns = [
            {
                field: 'orderType',
                headerName: 'Order Type',
                type: 'string',
                sortable: false,
                width: 100,
                editable: false,
            },
            {
                field: 'good_until',
                headerName: 'Good Until',
                width: 250,
                type: 'datetime',
                editable: true,
                renderCell: (params) => {
                    if (params.value) {
                        const date = new Date(params.value);
                        const options = {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            timeZoneName: 'short',
                        };
                        return new Intl.DateTimeFormat('en-US', options).format(date);
                    } else {
                        return ''
                    }
                },

                renderEditCell: (params) => {
                    const handleChange = (newValue) => {
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue });
                        params.api.stopCellEditMode({ id: params.id, field: params.field });
                    };

                    return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Select Date"
                                value={params.value ? dayjs(params.value) : null}
                                onChange={handleChange}
                                slotProps={{ textField: { variant: 'outlined' } }}
                                minDate={dayjs()}
                            />
                        </LocalizationProvider>
                    );
                }

            },
            {
                field: 'status',
                headerName: 'Status',
                type: 'singleSelect',
                sortable: false,
                width: 120,
                editable: true,
                valueOptions: gridConstantsData && gridConstantsData.interest_status.map(item => ({ label: item, value: item })),
                renderCell: (params) => renderStatus(params.row.status),
                valueParser: (newValue) => gridConstantsData.interest_status.includes(newValue) ? newValue : '', // Parse the entered value
                valueGetter: (params) => gridConstantsData.interest_status.includes(params.value) ? params.value : '', // Display the parsed value
            },
            {
                field: 'verb',
                headerName: 'Verb',
                type: 'string',
                sortable: false,
                width: 120,
            },
            {
                field: 'notional',
                headerName: 'Size',
                type: 'number',
                sortable: false,
                editable: true,
                width: 70,
            },
            {
                field: 'currency',
                headerName: 'Currency',
                type: 'string',
                sortable: false,
                editable: false,
                width: 100,
            },
            {
                field: 'issuer',
                headerName: 'Issuer',
                description: 'This column has the issuer ',
                sortable: false,
                width: 120,
            },
            {
                field: 'instrument_figi_ticker',
                headerName: 'Security',
                description: 'This column has the security ',
                sortable: false,
                width: 200,
            },

            {
                field: 'price',
                headerName: 'Price',
                type: 'number',
                width: 70,
                editable: true,
            },
            {
                field: 'owner_username',
                headerName: 'Sales',
                type: 'string',
                sortable: false,
                editable: false,
                width: 150,
            },
            {
                field: 'notes',
                headerName: 'Notes',
                type: 'string',
                sortable: false,
                editable: true,
                width: 250,
            },
            {
                field: 'created_at',
                headerName: 'Created At',
                width: 250,
                type: 'datetime',
                editable: false,
                renderCell: (params) => {
                    const date = new Date(params.value);
                    const options = {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        timeZoneName: 'short',
                    };
                    return new Intl.DateTimeFormat('en-US', options).format(date);
                },

            },
            {
                field: 'updated_at',
                headerName: 'Updated At',
                width: 250,
                type: 'datetime',
                editable: false,
                renderCell: (params) => {
                    const date = new Date(params.value);
                    const options = {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        timeZoneName: 'short',
                    };
                    return new Intl.DateTimeFormat('en-US', options).format(date);
                },

            },
        ]
        if (gridConstantsData && selectedUserRoles && !selectedUserRoles.mask_counterparty) {
            dynamicColumns.splice(0, 0, {
                field: 'counterparty_name',
                headerName: 'Counterparty',
                type: 'string',
                sortable: false,
                width: 200,
            })
        }
        return dynamicColumns;
    }, [selectedUserRoles, gridConstantsData])

    console.log(data)

    useEffect(() => {
        if (selectedUserRoles) {
            setIsManagement(selectedUserRoles.is_management)
            setIsRisk(selectedUserRoles.is_risk)
        }
    }, [selectedUserRoles]);

    const processRowUpdate = useCallback(
        async (updatedRow) => {
            const id = updatedRow.id;
            console.log('Updated Row: ', updatedRow)
            try {
                // Perform any necessary transformations or validations
                if (updatedRow.good_until && !dayjs(updatedRow.good_until).isValid()) {
                    setErrorMessage('Invalid Date!')
                    setShowError(true);
                    setShowSuccess(false)
                    // Hide the alert after 3 seconds
                    setTimeout(() => {
                        setShowError(false);
                    }, 3000); // 3000 milliseconds = 3 seconds
                    return
                }
                const updateResponse = await updateInterestMutation({ id, data: updatedRow }).unwrap();
                // Update local state with the edited row data
                setRows((prevRows) =>
                    prevRows.map((row) => (row.id === updatedRow.id ? { ...row, ...updateResponse.data } : row))
                );
                console.log('processrowUpdate')
                return { ...updatedRow, ...updateResponse.data }; // Return the updated row data
            } catch (error) {
                console.error('Error updating interest:', error);
                throw error; // Rethrow the error to let the data grid know the update failed
            }
        },
        [updateInterestMutation], // Dependencies
    );

    // Update local rows state whenever serverData changes
    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    useEffect(() => {
        if (interestFetchError || constantError) {
            setError(interestFetchError || constantError);
        } else {
            setError(null);
        }
    }, [interestFetchError, constantError]);

    const handleProcessRowUpdateError = (error) => {
        console.log(error)
        setError(error)
    }

    const saveSnapshot = useCallback(() => {
        if (apiRef?.current?.exportState && localStorage) {
            const currentState = apiRef.current.exportState();
            localStorage.setItem('orderDataGridState', JSON.stringify(currentState));
        }
    }, [apiRef]);

    useLayoutEffect(() => {
        const stateFromLocalStorage = localStorage?.getItem('orderDataGridState');
        setInitialState(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {});

        // handle refresh and navigating away/refreshing
        window.addEventListener('beforeunload', saveSnapshot);

        return () => {
            // in case of an SPA remove the event-listener
            window.removeEventListener('beforeunload', saveSnapshot);
            saveSnapshot();
        };
    }, [saveSnapshot]);

    console.log("Initial state:s",initialState)

    if (!initialState) {
        return <CircularProgress />;
    }

    return (
        <Fragment>
            <CssBaseline>
                {error && <Alert severity="error">{errorMessage}</Alert>}
                {(isLoading || !gridConstantsData || !data || constantsLoading) ? (
                    <Box
                        sx={{
                            width: '100%',
                            padding: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (

                    <Box sx={{ height: '90vh', width: '100%', padding: 2, display: 'flex', flexDirection: 'column' }}>
                        <AddInterestMenu />
                        <Box sx={{ flexGrow: 1, width: '100%', paddingTop: 2, display: 'flex', flexDirection: 'column' }}>
                            <DataGridPremium
                                columns={columns}
                                rows={rows}
                                editMode="row"
                                pagination
                                autoPageSize
                                processRowUpdate={processRowUpdate}
                                onProcessRowUpdateError={handleProcessRowUpdateError}
                                editRowsModel={editRowsModel}
                                onEditRowsModelChange={(newModel) => setEditRowsModel(newModel)}
                                isCellEditable={(params) => params.row.owner_username === selectedUser}
                                rowHeight={38}
                                disableColumnMenu
                                apiRef={apiRef}
                                slots={{ toolbar: isRisk || isManagement ? GridToolbar : NoExportToolbar }} // Conditionally render the toolbar
                                initialState={{
                                    ...initialState,
                                }}
                                sx={{
                                    flexGrow: 1,
                                    '& .MuiDataGrid-footerContainer': {
                                        position: 'sticky',
                                        bottom: 0,
                                        zIndex: 1,
                                        backgroundColor: '#fff', // Ensure footer stays visible on scroll
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                )}
            </CssBaseline>
        </Fragment>
    );
}