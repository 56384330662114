import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AddInterest from './AddSingleInterest';
import AddSecurity from '../add-security/AddSecurity'
// import AddMultiInterests from './AddMultiInterests'; 
// DISABLED MULTI INTEREST. GIVE LAYOUT SOME MORE THOUGH


export default function LabTabs() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Single Record" value="1" />
            {/* <Tab label="Multi Record" value="2" /> */}
            <Tab label="Add ISIN" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1"><AddInterest/></TabPanel>
        {/* <TabPanel value="2"><AddMultiInterests /></TabPanel> */}
        <TabPanel value="2"><AddSecurity /></TabPanel>
      </TabContext>
    </Box>
  );
}