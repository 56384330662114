import React, { useState, useEffect, Fragment } from 'react'
import { Box, Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import {
    DataGridPremium,
    GridToolbar,
} from '@mui/x-data-grid-premium';
import { useGetBondMonitorQuery } from '../services/ardentApi'
import { Typography } from '@mui/material';
import S3Upload from '../components/research/S3Upload'
import SecFile from '../components/research/FilingSearch'
import RVChartBondPage from '../components/research/RVChartBondPage';
import FilingUrl from '../components/research/FilingUrl'

export default function ResearchScreen() {
    const { data, error, isLoading } = useGetBondMonitorQuery();
    const [rows, setRows] = useState([]);

    // Update local rows state whenever serverData changes
    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    return (
        <Fragment>
            <CssBaseline>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%', padding: 2 }}>
                    {error ? (
                        <Alert severity="error">{error.error}</Alert>
                    ) : isLoading ? (
                        <CircularProgress />
                    ) :
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%', padding: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <S3Upload />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FilingUrl />
                                </Grid>
                            </Grid>
                     
                           <Grid sx={{marginTop: 2}} >
                           <RVChartBondPage />
                           </Grid>
                            
                      
                        </Box>
                    }
                </Box>
            </CssBaseline>
        </Fragment>
    )
}