import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { setCredentials, selectCurrentUser, resetCredentials, selectCurrentUserRoles } from '../features/auth/authSlice';
import { useLoginMutation } from '../services/ardentApi';
import { PAGES } from '../constants'
import ardFC from '../static/images/ardFC.png'; // Import the PNG file


export default function MenuBar() {
  const [currentUser, setCurrentUser] = useState(null);
  const selectedUser = useSelector(selectCurrentUser);
  const selectedUserRoles = useSelector(selectCurrentUserRoles);
  const [filteredPages, setFilteredPages] = useState(PAGES)
  const [isRisk, setIsRisk] = useState(false)
  const [isMgmt, setIsMgmt] = useState(false)
  const dispatch = useDispatch();
  const location = useLocation();
  const [error, setError] = useState(null);

  const [login, { isLoading }] = useLoginMutation();

  useEffect(() => {
    // Check local storage for user and token when component mounts
    const storedUser = sessionStorage.getItem('user');
    const storedToken = sessionStorage.getItem('token');
    const storedRoles = sessionStorage.getItem('roles');
    if (storedUser && storedToken ) {
      // If user and token is in local storage then log back in
      const handleLogin = async () => {
        try {
          await login({ user: JSON.parse(storedUser), token: storedToken });
          // Dispatch action to set user and token in Redux state
          dispatch(setCredentials({ user: JSON.parse(storedUser), token: storedToken,  roles: JSON.parse(storedRoles) }));
        } catch (error) {
          setError(error); // Set error message
        }
      };
      handleLogin()
    }
  }, [dispatch, login]);

  const handleLogout = () => {
    dispatch(resetCredentials());
  };

  useEffect(() => {
    setCurrentUser(selectedUser);
  }, [selectedUser]); // Listen for changes in selectCurrentUser and update the currentUser state

  return (
    <Box sx={{ flexGrow: 1, width: '100%', padding: 1 }}>
      <AppBar position="static" style={{  backgroundColor: '#f0f0f0' }}>
        <Toolbar>
        <img src={ardFC} alt="Ardent Financial Services" style={{ width: 150, height: 50 }} />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {PAGES.map((page) => (
              <Link
                key={page}
                style={{
                  textDecoration: 'none',
                  color: '#333333',
                  marginLeft: '20px',
                  borderBottom: location.pathname === `/${page.toLowerCase()}` ? '2px solid blue' : 'none',
                }}
                to={selectedUser ? page.toLowerCase() : '/'}
              >
                {page}
              </Link>
            ))}
          </Box>
          {currentUser ? <Box marginLeft='auto'><Grid container direction="column" alignItems="center">
            <Typography style={{ textDecoration: 'none', color: '#333333' }}>
              Hello {currentUser}
            </Typography>
            <Box mt={2}>
              <Link
                to={'/'}
                style={{ textDecoration: 'none', color: '#333333' }}
                onClick={handleLogout}
              >
                Log Out
              </Link>
            </Box>
          </Grid>
          </Box>
            :
            <Link
              style={{
                textDecoration: 'none',
                color: '#333333',
                marginLeft: '20px',
                border: '1px solid',
                paddingTop: '2px',
                paddingBottom: '5px',
                paddingRight: '5px',
                paddingLeft: '5px',
                borderRadius: '4px'
              }}
              to={'/login'}
            >
              Login
            </Link>
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}