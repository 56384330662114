import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import { useAddSecurityMutation } from '../../services/ardentApi';

const AddSecurity = () => {
    const [addSecurity, { isLoading, isError, error: mutationError }] = useAddSecurityMutation(); // Call the useAddPostMutation hook
    const [error, setError] = useState(false);
    const [addSecurityMessage, setAddSecurityMessage] = useState('')
    const [isin, setIsin] = useState('');

    const handleGetISIN = async () => {
        try {
            // Validate that the ISIN is 12 characters
            if (isin.length !== 12) {
                setError(true);
                setIsin('')
                setTimeout(() => {
                    setError(false); // Clear the error message after 5 seconds
                    setAddSecurityMessage('')
                }, 3000); // 3000 milliseconds = 5 seconds
                return;
            }

            // Clear error state if validation passes
            setError(false);

            // Proceed with your logic for handling ISIN
            const dataPayload = {
                idIsin: isin
                // Add other data if needed
            };
            try {
               const response =  await addSecurity(dataPayload)
               console.log('Security response!',response)
                if (response.data.hasOwnProperty('error')) {
                    console.log(response.data['error'])
                    setAddSecurityMessage(response.data['error'])
                } else {
                    // console.log('Successful insert!',response.data)
                    setAddSecurityMessage(response.data)
                }
              } catch {
                console.error('Failed to send counterparty to db: ', mutationError)
        
              }
            console.log("Data payload:", dataPayload);

        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission
        handleGetISIN(); // Call your function to handle ISIN
    };

    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1 },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <Input
                placeholder="Add ISIN"
                value={isin}
                onChange={(e) => setIsin(e.target.value)}
                error={error}
            />
            <Button type='submit'>Submit</Button>
            {error && <p style={{ color: 'red' }}>ISIN must be 12 characters long.</p>}
            {addSecurityMessage && <p style={{ color: 'blue' }}>{addSecurityMessage}</p>}
        </Box>
    );
};

export default AddSecurity;
