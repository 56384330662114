import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation  } from 'react-router-dom';
import MenuBar from './components/MenuBar';
import InterestScreen from './screens/InterestScreen'
import CRMScreen from './screens/CRMScreen'
import AdminScreen from './screens/AdminScreen';
import RiskScreen from './screens/RiskScreen';
import LoginScreen from './screens/LoginScreen';
import HomeScreen from './screens/HomeScreen';
import ResearchScreen from './screens/ResearchScreen';
import FeedbackAndFeatures from './screens/FeedbackAndFeatures';
import TradingScreen from './screens/TradingScreen';
import BlotterMO from './screens/BlotterScreen';
import UnauthorisedScreen from './screens/UnauthorisedScreen';
import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../src/features/auth/authSlice';

// Component to handle routing and MenuBar display
const AppRoutes = () => {
  const location = useLocation();
  const selectedUserRoles = useSelector(selectCurrentUserRoles);
  const [isManagement, setIsManagement] = useState(false);

  useEffect(() => {
    if (selectedUserRoles) {
      setIsManagement(selectedUserRoles.is_management);
    }
  }, [selectedUserRoles]);

  return (
    <>
      {location.pathname !== '/' && location.pathname !== '/login' && <MenuBar />}
      <Routes>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/interests' element={<InterestScreen />} />
        <Route path='/crm' element={<CRMScreen />} />
        <Route path='/research' element={<ResearchScreen />} />
        <Route path='/trading' element={<TradingScreen />} />
        <Route path='/blotter' element={<BlotterMO />} />
        <Route path='/risk' element={<RiskScreen />} />
        <Route path='/feedback' element={<FeedbackAndFeatures />} />
        <Route path='/admin' element={<AdminScreen />} />
        <Route path='/login' element={<LoginScreen />} />
      </Routes>
    </>
  );
};

// Main App component with Router
function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
