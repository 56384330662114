import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, TextField, Typography, IconButton, InputAdornment, Paper } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useLazyGetPresignedUrlQuery, useGetTemporaryCredentialsQuery, useLazyGetSignedUrlQuery } from '../../services/ardentApi';  // Adjust the import path as needed
import axios from 'axios';


const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');
  const [credentials, setCredentials] = useState(null);
  const [fileUrl, setFileUrl] = useState('');

  // Lazy query hook for fetching the pre-signed URL
  const [triggerGetPresignedUrl, { data: presignedData, isFetching: isFetchingPresignedUrl, error: presignedError }] = useLazyGetPresignedUrlQuery();
  const { data: credentialsData, error: credentialsError, isLoading: isLoadingCredentials } = useGetTemporaryCredentialsQuery();
  const [triggerGetSignedUrl, { isFetching: isFetchingSignedUrl, error: signedUrlError }] = useLazyGetSignedUrlQuery();

  useEffect(() => {
    // Fetch temporary credentials when the component mounts
    if (credentialsData) {
      setCredentials(credentialsData);
    } else if (credentialsError) {
      console.error('Error fetching temporary credentials', credentialsError);
      setMessage('Error fetching temporary credentials');
    }
  }, [credentialsData, credentialsError]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      setMessage('Please select a file to upload.');
      return;
    }

    setUploading(true);
    setMessage('');

    // Trigger the lazy query to get the pre-signed URL
    triggerGetPresignedUrl({
      filename: file.name,
      filetype: file.type,
    });
  };

  const handleGenerateUrl = async (fileName) => {
    try {
      const { data } = await triggerGetSignedUrl(fileName);
      setFileUrl(data.url);
      setMessage('URL generated successfully!');
      setTimeout(() => setMessage(''), 3000); // Clear message after 3 seconds
    } catch (error) {
      console.error('Error generating URL', error);
      setMessage('Error generating URL');
    }
  };

  useEffect(() => {
    // Upload the file when pre-signed URL data and credentials are available
    const uploadFile = async () => {
      if (presignedData && credentials && file) {
        try {
          const { url, fields } = presignedData;

          const formData = new FormData();
          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append('file', file);

          await axios.post(url, formData);

          setMessage('File uploaded successfully!');
          handleGenerateUrl(file.name);
          setFile(null); // Clear the file input
          document.getElementById('file-input').value = ''; // Clear the file input field
        } catch (uploadError) {
          setMessage('File upload failed.');
          console.error('File upload error:', uploadError);
        } finally {
          setUploading(false);
        }
      }
    };

    uploadFile();
  }, [presignedData, credentials, file]);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(fileUrl).then(() => {
      setMessage('URL copied to clipboard!');
      setTimeout(() => setMessage(''), 3000); // Clear message after 3 seconds
    }).catch(err => {
      console.error('Failed to copy URL:', err);
    });
  };

  return (
    <Paper style={{ padding: 5, border: '1px solid #d3d3d3' }}>
      <Box sx={{ width: '100%', padding: 2, marginBottom: 2 }}>
        <Typography variant="h6" gutterBottom>
          Upload a File to S3
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <TextField
            id="file-input"
            type="file"
            onChange={handleFileChange}
          />
          {(uploading || isFetchingPresignedUrl || isLoadingCredentials) ? (
            <CircularProgress sx={{ marginLeft: 1 }} />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              sx={{ marginLeft: 1 }}
            >
              Upload
            </Button>
          )}
        </Box>
        {fileUrl && (
          <Box mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label="Download URL"
              value={fileUrl}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCopyUrl}>
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}
        {message && (
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            {message}
          </Typography>
        )}
        {presignedError && (
          <Typography variant="body1" color="error" sx={{ marginTop: 2 }}>
            Error: {presignedError.message}
          </Typography>
        )}
        {credentialsError && (
          <Typography variant="body1" color="error" sx={{ marginTop: 2 }}>
            Error: {credentialsError.message}
          </Typography>
        )}
        {signedUrlError && (
          <Typography variant="body1" color="error" sx={{ marginTop: 2 }}>
            Error: {signedUrlError.message}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default FileUpload;