import React, { useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import dayjs from 'dayjs';
import { Button } from '@mui/material';

const RVChartBondScatterChart = ({ data, onVisiblePointsChange }) => {
  const [showDataLabels, setShowDataLabels] = useState(false); // State to toggle data labels
  const chartComponentRef = useRef(null);
  const settingExtremesRef = useRef(false); // Flag to prevent recursive setExtremes calls

  const [chartOptions, setChartOptions] = useState({
    chart: {
      height: '35%',
      type: 'scatter',
      zoomType: 'xy',
    },
    title: {
      text: 'RV Chart',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Workout Date',
      },
      events: {
        setExtremes: function (e) {
          if (settingExtremesRef.current) return; // Prevent recursive call

          const chart = chartComponentRef.current.chart;
          if (!e.min || !e.max) {
            // If no extremes are set, this indicates a reset zoom action
            handleResetZoom(chart);
            return;
          }

          // Set the flag to indicate that we're setting extremes programmatically
          settingExtremesRef.current = true;

          // Filter visible points based on the current x-axis extremes
          const visiblePoints = chart.series[0].data.filter(
            (point) => point.x >= e.min && point.x <= e.max
          );

          // Invoke the callback to send the count of visible points to the parent
          if (onVisiblePointsChange) {
            onVisiblePointsChange(visiblePoints.length);
          }

          if (visiblePoints.length > 0) {
            // Calculate new min and max for y-axis based on visible points
            const yValues = visiblePoints.map((point) => point.y);
            const minY = Math.min(...yValues);
            const maxY = Math.max(...yValues);

            // Set new extremes for y-axis based on visible data points
            chart.yAxis[0].setExtremes(minY, maxY, true, false);
          }

          // Reset the flag after setting extremes
          settingExtremesRef.current = false;
        },
      },
    },
    yAxis: {
      title: {
        text: 'YAS Bond Spread',
      },
      events: {
        setExtremes: function (e) {
          if (settingExtremesRef.current) return; // Prevent recursive call

          const chart = chartComponentRef.current.chart;
          if (!e.min || !e.max) {
            // If no extremes are set, this indicates a reset zoom action
            handleResetZoom(chart);
            return;
          }

          // Set the flag to indicate that we're setting extremes programmatically
          settingExtremesRef.current = true;

          // Filter visible points based on the current y-axis extremes
          const visiblePoints = chart.series[0].data.filter(
            (point) => point.y >= e.min && point.y <= e.max
          );

          // Invoke the callback to send the count of visible points to the parent
          if (onVisiblePointsChange) {
            onVisiblePointsChange(visiblePoints.length);
          }

          if (visiblePoints.length > 0) {
            // Calculate new min and max for x-axis based on visible points
            const xValues = visiblePoints.map((point) => point.x);
            const minX = Math.min(...xValues);
            const maxX = Math.max(...xValues);

            // Set new extremes for x-axis based on visible data points
            chart.xAxis[0].setExtremes(minX, maxX, true, false);
          }

          // Reset the flag after setting extremes
          settingExtremesRef.current = false;
        },
      },
    },
    tooltip: {
      pointFormat:
        '<b>{point.name}</b><br/>Workout Date: {point.x:%e %b %Y}<br/>YAS Bond Spread: {point.y}',
    },
    series: [
      {
        name: 'Bonds',
        data: [], // Initial empty data
        marker: {
          radius: 5,
        },
        dataLabels: {
          enabled: showDataLabels, // Use state to control visibility
          format: '{point.name}',
          style: {
            fontSize: '10px',
            color: '#000000',
          },
        },
      },
    ],
    resetZoomButton: {
      position: {
        align: 'right', // Align the button to the right
        verticalAlign: 'top', // Align the button to the top
        x: -20, // X offset
        y: 10, // Y offset
      },
      relativeTo: 'chart', // Position relative to the chart
      theme: {
        style: {
          fontSize: '10px',
          color: '#333333',
          background: 'white',
          border: '1px solid silver',
          padding: '5px',
          borderRadius: '3px',
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  });

  // Function to handle reset zoom action
  const handleResetZoom = (chart) => {
    const totalPoints = chart.series[0].data.length;

    // Pass the total count of data points when zoom is reset
    if (onVisiblePointsChange) {
      onVisiblePointsChange(totalPoints);
    }
  };

  useEffect(() => {
    const formattedData =
      data.length > 0
        ? data
            .filter(
              (bond) =>
                bond.workout_date &&
                bond.yas_bond_spread !== null &&
                !isNaN(bond.yas_bond_spread)
            )
            .map((bond) => ({
              x: dayjs(bond.workout_date).valueOf(),
              y: Number(bond.yas_bond_spread),
              name: bond.bond_ticker,
            }))
        : [];

    setChartOptions((prevOptions) => ({
      ...prevOptions,
      series: [
        {
          ...prevOptions.series[0],
          data: formattedData,
          dataLabels: {
            ...prevOptions.series[0].dataLabels,
            enabled: showDataLabels, // Update based on toggle state
          },
        },
      ],
    }));
  }, [data, showDataLabels]); // Re-run effect when data or toggle state changes

  // Handler to toggle data labels
  const handleToggleDataLabels = () => {
    setShowDataLabels((prev) => !prev);
  };

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginTop: 5 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleToggleDataLabels}
        style={{ marginBottom: '10px' }}
      >
        {showDataLabels ? 'Hide Data Labels' : 'Show Data Labels'}
      </Button>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { width: '100%', height: '100%', flex: 1 } }}
        ref={chartComponentRef}
      />
    </div>
  );
};

export default RVChartBondScatterChart;


