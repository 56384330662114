import React, { useState, useEffect, useCallback, Fragment, useMemo, useLayoutEffect } from 'react'
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import {
    DataGridPremium,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarColumnsButton,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { parseISO, format } from 'date-fns';
import { useGetBlotterMOQuery } from '../services/ardentApi'
import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../features/auth/authSlice';
import { Typography } from '@mui/material';

function NoExportToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}



export default function BlotterMO() {
    const { data, error, isLoading } = useGetBlotterMOQuery();
    const selectedUserRoles = useSelector(selectCurrentUserRoles);
    const apiRef = useGridApiRef();
    const [isManagement, setIsManagement] = useState(false)
    const [isRisk, setIsRisk] = useState(false)
    const [rows, setRows] = useState([]);
    const [initialState, setInitialState] = useState();


    // Define columns
   const columns = useMemo(() => {
        const dynamicColumns = [
            {
                field: 'trade_date',
                headerName: 'Trade Date',
                width: 150,
                type: 'datetime',
                editable: false,
                valueGetter: (params) => {
                    // Assuming the date is in ISO format; use `parse` for other formats
                    const parsedDate = parseISO(params.value);
                    return parsedDate;
                },
                valueFormatter: (params) => {
                    if (!params.value) return "";
                    // Format the date as needed; this example uses a common datetime format
                    return format(params.value, "yyyy-MM-dd HH:mm:ss");
                },
                renderCell: (params) => {
                    // You can further customize the displayed value in renderCell
                    return params.formattedValue || "Invalid Date";
                },
            },

            {
                field: 'isin',
                headerName: 'ISIN',
                type: 'string',
                sortable: false,
                width: 150,
            },
            {
                field: 'description',
                headerName: 'Issue',
                type: 'string',
                sortable: false,
                width: 350,
            },
            {
                field: 'verb',
                headerName: 'Verb',
                type: 'string',
                sortable: false,
                width: 100,
            },


            {
                field: 'trade_px',
                headerName: 'Trade Px',
                type: 'number',
                sortable: false,
                width: 100,
                editable: false,
            },
            {
                field: 'principal_amt',
                headerName: 'Principal Amt',
                type: 'number',
                sortable: false,
                width: 120,
            },
            {
                field: 'notional',
                headerName: 'Notional',
                type: 'number',
                sortable: false,
                editable: true,
                width: 120,
            },
            {
                field: 'ccy',
                headerName: 'Currency',
                type: 'string',
                sortable: false,
                editable: false,
                width: 100,
            },
            {
                field: 'trade_accrual',
                headerName: 'Trade Accrual',
                type: 'number',
                sortable: false,
                width: 100,
            },
            {
                field: 'settlement_amt',
                headerName: 'Settlement Amt',
                type: 'number',
                sortable: false,
                width: 100,
            },

            {
                field: 'fx_rate',
                headerName: 'FX Rate',
                type: 'number',
                width: 70,
                editable: true,
            },
            {
                field: 'settle_date',
                headerName: 'Settle Date',
                width: 250,
                type: 'datetime',
                editable: false,
                renderCell: (params) => {
                    const date = new Date(params.value);
                    const options = {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                    };
                    return new Intl.DateTimeFormat('en-US', options).format(date);
                },

            },
        ]
        if(selectedUserRoles && !selectedUserRoles.mask_counterparty) {
           dynamicColumns.splice(2,0, {
                field: 'counterparty',
                headerName: 'Counterparty',
                type: 'string',
                sortable: false,
                width: 300,
            },
            {
                field: 'counterparty_full_name',
                headerName: 'Counterparty Full Name',
                type: 'string',
                sortable: false,
                width: 150,
            },)
        }
        return dynamicColumns;
    }, [selectedUserRoles])


    // Update local rows state whenever serverData changes
    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    useEffect(() => {
        if (selectedUserRoles) {
            setIsManagement(selectedUserRoles.is_management)
            setIsRisk(selectedUserRoles.is_risk)
        }
    }, [selectedUserRoles]);

    const saveSnapshot = useCallback(() => {
        if (apiRef?.current?.exportState && localStorage) {
            const currentState = apiRef.current.exportState();
            localStorage.setItem('blotterDataGridState', JSON.stringify(currentState));
        }
    }, [apiRef]);

    useLayoutEffect(() => {
        const stateFromLocalStorage = localStorage?.getItem('blotterDataGridState');
        setInitialState(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {});

        // handle refresh and navigating away/refreshing
        window.addEventListener('beforeunload', saveSnapshot);

        return () => {
            // in case of an SPA remove the event-listener
            window.removeEventListener('beforeunload', saveSnapshot);
            saveSnapshot();
        };
    }, [saveSnapshot]);

    if (!initialState) {
        return <CircularProgress />;
    }

    return (
        <Fragment>
            <CssBaseline>
                <Box sx={{ height: '100vh', width: '100%', padding: 2 }}>
                    {error ? (
                        <Alert severity="error">{error.error}</Alert>
                    ) : isLoading ? (
                        <CircularProgress />
                    ) :
                    // <h1>Filtered blotter will be added.</h1>
                    <Fragment>
                    <Typography paddingBottom={1} variant="h4" >
                    Blotter
                    </Typography>
                        <DataGridPremium
                            columns={columns}
                            rows={rows}
                            pagination
                            autoPageSize
                            rowHeight={38}
                            apiRef={apiRef}
                            initialState={{
                              ...initialState,
                            }}
                            disableColumnMenu
                            slots={{ toolbar: isRisk || isManagement ? GridToolbar : NoExportToolbar }} // Conditionally render the toolbar
                        />
                        </Fragment>
                    }
                </Box>
            </CssBaseline>
        </Fragment>
    )
}