import * as React from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import InfoIcon from '@mui/icons-material/Info';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoneIcon from '@mui/icons-material/Done';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';

const StyledChip = styled(Chip)(({ theme }) => ({
    justifyContent: 'left',
    '& .icon': {
      color: 'inherit',
    },
    '&.Open': {
      color: (theme.vars || theme).palette.info.dark,
      border: `1px solid ${(theme.vars || theme).palette.info.main}`,
    },
    '&.Filled': {
      color: (theme.vars || theme).palette.success.dark,
      border: `1px solid ${(theme.vars || theme).palette.success.main}`,
    },
    '&.PartiallyFilled': {
      color: (theme.vars || theme).palette.warning.dark,
      border: `1px solid ${(theme.vars || theme).palette.warning.main}`,
    },
    '&.Rejected': {
      color: (theme.vars || theme).palette.error.dark,
      border: `1px solid ${(theme.vars || theme).palette.error.main}`,
    },
    '&.Closed': {
      color: (theme.vars || theme).palette.error.dark,
      border: `1px solid ${(theme.vars || theme).palette.error.main}`,
    },
    
  }));

  const Status = React.memo((props) => {
    const { status } = props;

    let icon = null;
    if (status === 'Rejected') {
      icon = <ReportProblemIcon className="icon" />;
    } else if (status === 'Open') {
      icon = <InfoIcon className="icon" />;
    } else if (status === 'PartiallyFilled') {
      icon = <AutorenewIcon className="icon" />;
    } else if (status === 'Filled') {
      icon = <DoneIcon className="icon" />;
    } else if (status === 'Closed') {
      icon = <CancelIcon className="icon" />;
    }

    let label = status;
    if (status === 'PartiallyFilled') {
      label = 'Partially Filled';
    }

    return (
      <StyledChip className={status} icon={icon} size="small" label={label} variant="outlined" />
    );
  });

  export function renderStatus(params) {
    if (params == null) {
      return '';
    }
    return <Status status={params} />;
  }
