import React, { useState, useEffect, useCallback } from 'react';
import {  Paper } from '@mui/material';
import RVChartBondFilter from './RVChartBondFilter';
import RVChartBondScatterChart from './RVBonds';
import { useGetRVBondsQuery } from '../../services/ardentApi'; 

const RVChartBondPage = () => {
  const { data: bonds = [], isLoading } = useGetRVBondsQuery();
  const [filteredData, setFilteredData] = useState([]);
  const [visiblePointsCount, setVisiblePointsCount] = useState(null);

  // Initialize with all data when bonds are fetched
  useEffect(() => {
    setFilteredData(bonds);
  }, [bonds]);

  const handleFilterChange = useCallback((filters) => {
    if (JSON.stringify(filters.filteredBonds) !== JSON.stringify(filteredData)) {
      console.log('filters:', filters);
      setFilteredData(filters.filteredBonds);
    }
  }, [filteredData]);
  


  // Function to handle the count of visible points
  const handleVisiblePointsChange = (count) => {
    setVisiblePointsCount(count);
  };

  return (
<Paper style={{ padding: 12, border:  '1px solid #d3d3d3'  }}>
      {/* Pass the bonds data to the filter to apply filtering */}
      <RVChartBondFilter bonds={bonds} onFilterChange={handleFilterChange} isLoading={isLoading} visiblePointsCount={visiblePointsCount} />
      <RVChartBondScatterChart data={filteredData} onVisiblePointsChange={handleVisiblePointsChange} />
    </Paper>
  );
};

export default RVChartBondPage;



