import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { store } from './store'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey('a87383775ebb2d8f8357ecd79b5dda1cTz04NjQ2OSxFPTE3NDIwNDE1OTYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
